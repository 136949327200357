import React, { useState, useEffect, useContext } from 'react'
import Popup from '../../Product/components/organisms/Popup'
import ContactUs from '../../Themes/LotsAuction/ContactUs'
import { truncate } from 'lodash'
import productContext from '../../Product/context/product/productContext'
const GetQuestions = (props) => {
    const { getAllQuestions, askaquestions } = useContext(productContext)
    const [data, setData] = useState([])
    useEffect(() => {
        getAllQuestions({
            site_id: global?.storeDetails?.site_id,
            project_id: props.project_id,
        })
    }, [])
    useEffect(() => {
        setData(askaquestions)
    }, [askaquestions])

    return (
        <div
            className="container d-flex"
            style={{ gap: '10px', flexWrap: 'wrap', height: '500px', overflow: 'auto' }}
        >
            {data.map((val) => (
                <div className="cardQA">
                    <>
                        <div className="card-content-QA">
                            <div className="info-group">
                                <div className="label">Created Date:</div>
                                <div className="value">{val.created_date}</div>
                            </div>

                            <div className="info-group">
                                <div className="label">Name:</div>
                                <div className="value">{val.name}</div>
                            </div>
                            <div className="info-group">
                                <div className="label">Messages:</div>
                                <div className="value">{val.messages}</div>
                            </div>
                        </div>
                    </>
                </div>
            ))}
        </div>
    )
}

export default GetQuestions
