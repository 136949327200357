import React, { useContext, useEffect } from 'react'
import './ContactUs.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { mapData, handleRedirectInternal } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import AuthContext from '../../../Product/context/auth/authContext'
import UserContext from '../../../Product/context/user/userContext'
import GoogleMap from './GoogleMap'
import Layout from '../Layout'

function ContactUs({ hideComp, blockRedirection, handleClose, removePhone, selectedProduct }) {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const { send_contactus, responseStatus } = userContext
    const { user, loadUser } = authContext
    const history = useHistory()
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const contactAddress = global?.storeDetails?.address

    const validationArray = Yup.object({
        name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'Special characters not allowed')
            .max(50, 'Maximum 50 characters')
            .required('Required'),

        from_email: Yup.string().email('Invalid email format').required('Required'),
        // phone_number: removePhone
        //     ? Yup.string()
        //     : Yup.string()
        //           .trim()
        //           .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
        //           .min(17, 'Invalid!')
        //           .max(21, 'Maximum 14 digits')
        //           .required('Required'),
        subject: Yup.string().required('Required'),
        messages: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            last_name: '',
            from_email: '',
            phone_number: '',
            subject: '',
            messages: '',
            is_send_email: 1,
            project_id: selectedProduct ? selectedProduct?.id : undefined,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            send_contactus(values)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'send_contactus') {
                if (responseStatus.status === 'success') {
                    if (!blockRedirection) {
                        handleRedirectInternal(history, '')
                    } else {
                        handleClose()
                    }
                }
            }
        }
    }, [responseStatus])

    const contactInfo = [
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-sm-6 col-12',
            autoFocus: true,
            formik: formik,
        },

        {
            label: 'Email address',
            name: 'from_email',
            type: 'text',
            placeholder: 'Enter your email address',
            class: 'col-sm-6 col-12',
            formik: formik,
        },
        {
            label: 'Phone number',
            name: 'phone_number',
            type: 'text',
            placeholder: 'Enter your phone number',
            class: `col-sm-6 col-12 ${removePhone ? 'd-none' : ''}`,
            formik: formik,
            countryCodeEditable: false,
            startAdornment:
                global?.storeConfigration?.disable_phone_country_code?.value == 1 ? '+1' : '',
        },
        {
            label: 'Subject',
            name: 'subject',
            type: 'text',
            placeholder: 'Enter your subject',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Message',
            name: 'messages',
            type: 'textarea',
            placeholder: 'Enter your message',
            class: 'col-12',
            formik: formik,
        },
    ]
    useEffect(() => {
        loadUser()
    }, [])

    useEffect(() => {
        if (user) {
            formik.values.name = user?.first_name + ' ' + user?.last_name || ''
            formik.values.phone_number = user?.phone || ''
            formik.values.from_email = user?.email || ''
            formik.values.subject = 'General Enquiry'
        }
    }, [user])

    return (
        <Layout hideComp={hideComp}>
            <div className="contact-main-container">
                {!hideComp && (
                    <div className="contact-banner">
                        <img src="/assets/images/contact_us.png" alt="" />
                    </div>
                )}
                <div className={`"container ${hideComp ? '' : 'cu-margin-125'}"`}>
                    <div className="row">
                        <div
                            className={hideComp ? 'col-xs-12  padtop' : 'col-xs-12 col-md-8 padtop'}
                        >
                            <div className="contactform">
                                {hideComp ? <></> : <div className="cu-head">Contact us</div>}
                                <div className="text-center scontact-us-wrap flex-btnscol">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            {Object.values(mapData(contactInfo))}
                                            <div className="col-12">
                                                <PrimaryButton label="Submit" type="submit" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {!hideComp ? (
                            <div className="col-xs-12 col-md-4 padtop contact-us-data">
                                <div className="contactform cfRt">
                                    {/* <div className="cu-head">Reach Us</div> */}

                                    <div className="media">
                                        {/* <div className="media-left">
                                                <span className="material-icons">location_on</span>
                                            </div> */}
                                        <div className="media-body">
                                            <h5 className="ru-head">Mailing Address</h5>
                                            <span className="ru-con">
                                                {global.storeDetails?.address}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="media">
                                        {/* <div className="media-left">
                                                <span className="material-icons">Telephone</span>
                                            </div> */}
                                        <div className="media-body">
                                            <h5 className="ru-head">Telephone</h5>
                                            <span className="ru-con">
                                                {global.storeDetails?.phone}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="media">
                                        {/* <div className="media-left">
                                                <span className="material-icons">mail</span>
                                            </div> */}
                                        <div className="media-body">
                                            <h5 className="ru-head">General Email</h5>
                                            <span className="ru-con">
                                                {' '}
                                                {global.storeDetails?.contact_email}
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        <GoogleMap></GoogleMap>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    {/* <div className={`"container ${hideComp ? '' : 'cu-margin-125'}"`}>
                        <div className="row">
                            <div
                                className={
                                    hideComp ? 'col-xs-12  padtop' : 'col-xs-12 col-md-8 padtop'
                                }
                            >
                                <div>
                                    <h3>Mailing Address</h3>
                                    <p>{global.storeDetails?.address}</p>
                                </div>
                                <div>
                                    <h3>Telephone</h3>
                                    <p>Phone: {global.storeDetails?.phone}</p>
                                </div>
                                <div>
                                    <h3>General Email</h3>
                                    <p>{global.storeDetails?.contact_email}</p>
                                </div>
                                <div>
                                    <GoogleMap></GoogleMap>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </Layout>
    )
}

export default ContactUs
