import React, { useContext, useEffect, useState } from 'react'
import Popup from '../../Product/components/organisms/Popup'
import CategoriesList from './index'
import ProductContext from '../../Product/context/product/productContext'

const CategoryModal = (props) => {
    const productContext = useContext(ProductContext)
    const [load, setLoad] = useState(true)
    const [categorylist, setCategoryList] = useState([])
    const { getallcatanditems, get_all_cat } = productContext
    useEffect(() => {
        if (parseInt(props.lotof) > 0) {
            getallcatanditems({
                site_id: global?.storeDetails?.site_id,
                lotof: props.lotof,
                from: 'get_all_items_inside',
            })
        }
    }, [props.lotof])
    useEffect(() => {
        if (get_all_cat.from == 'get_all_items_inside') {
            if (get_all_cat.data.length > 0) {
                setCategoryList(get_all_cat.data.length > 0 ? get_all_cat.data : [])
            } else {
                setCategoryList([])
            }
            setLoad(false)
        }
    }, [get_all_cat])

    return (
        <Popup
            {...props}
            size="md"
            modalClass={`save-search-modal ${props.classNames} categoryModal`}
            modaltitle="Categories"
        >
            {load ? (
                <div>Loading...</div>
            ) : (
                <CategoriesList
                    onClick={(cat) => props.handleClick(cat)}
                    category={categorylist.filter(
                        (data) => data?.name?.toLowerCase()?.trim() != 'adult',
                    )}
                />
            )}
        </Popup>
    )
}

export default CategoryModal
