import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './MultisellerListCard.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import Timer from '../../Product/common/timer'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import {
    dateFormatFront,
    dateFromatFrontDayMonth,
    handleRedirectInternal,
    removeHTMLTags,
} from '../../Product/common/components'
import { currencyFormat, sellerNameFormat } from '../../Product/common/components'
import { Button, Tooltip, Popover, useMediaQuery } from '@material-ui/core'
import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import moment from 'moment-timezone'
import AddToCalendar from '../../Product/components/molecules/AddToCalendar'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import AuthContext from '../../Product/context/auth/authContext'
import CurrencyConverterFormat from '../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'
import CartContext from '../../Product/context/cart/cartContext'
import Facebook from '../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../utils/CommonFunctionality/Share/Pinterest'
import Copy from '../../utils/CommonFunctionality/Share/Copy'
import ReadMore from '../ReadMore'
import { getImageurl } from '../../utils/commonFunctions'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import Bidhistory from '../Bidhistory'

const MultisellerListCard = ({
    data,
    details,
    isFrame,
    lotData,
    type,
    bidList,
    handleClick,
    handleLotClick,
    bidBtnLabel,
    theme,
    bidStatus,
    timerTheme,
    // lotCount,
    auction_and_buynow,
    className,
    cardTheme,
    standalonePage,
    card_theme,
    auctionTimer,
    viewProduct,
    setViewProduct,
    lotDetails,
    is_registered,
    setAlert,
    private_auction,
    openCategory,
    showQty,
    multipleImages,
}) => {
    const addDefaultSrc = (e) => {
        e.target.src = `${global?.storeDetails?.logoValue}`
        e.target.className = `gridImage default`
    }
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { isAuthenticated, user } = authContext
    const cartContext = useContext(CartContext)
    const [selectedProduct, setSelectedProduct] = useState({})
    const { addToCart, getUserCart, removeFromCart, responseStatus, buyer_cartitems } = cartContext

    const [active, setActive] = useState(false)
    const [historyData, setHistoryData] = useState({})

    const [bidText, setBidText] = useState('Bid Now')
    const matches = useMediaQuery('(max-width:768px)')
    const getBidText = (data) => {
        if (
            isAuthenticated &&
            (data?.latestbid_user != null || data?.highest_bidder_user_id != null) &&
            user?.id != undefined
        ) {
            if (data?.auction) {
                if (
                    (data?.latestbid_user == user?.id ||
                        data?.highest_bidder_user_id == user?.id) &&
                    data?.wprice >= data?.rprice
                ) {
                    if (data?.market_status == 'open') {
                        setBidText('Winning')
                    } else if (data?.market_status == 'sold') {
                        setBidText('Won')
                    }
                } else if (
                    data?.latestbid_user != user?.id &&
                    (data?.currentUserBidAmount || data?.bid_or_not || data?.cbidnot)
                ) {
                    if (data?.market_status == 'open') {
                        setBidText('Outbid')
                    } else if (data?.market_status == 'sold') {
                        setBidText('Lost')
                    }
                } else {
                    setBidText('Bid Now')
                }
            }
        } else {
            setBidText('Bid Now')
        }
    }
    useEffect(() => {
        getBidText(lotData)
    }, [lotData])
    useEffect(() => {
        if (buyer_cartitems.results) {
            if (lotData?.id) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: lotData?.id,
                })
                position === -1 ? setActive(false) : setActive(true)
            }
        }
    }, [buyer_cartitems.results, lotData?.id])

    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'addToCart') {
    //             if (responseStatus.status === 'success') {
    //                 getUserCart()
    //             }
    //         } else if (responseStatus.from === 'removeFromCart') {
    //             if (responseStatus.status === 'success') {
    //                 getUserCart()
    //             }
    //         }
    //     }
    // }, [responseStatus])
    const isHyperMicroTheme = document.querySelector('body').classList.contains('hyperMicro')
    const isPrimeBidsTheme = document.querySelector('body').classList.contains('primeBids')

    const [shareAnchorEl, setShareAnchorEl] = useState(null)
    const handleShareClick = (event) => {
        setShareAnchorEl(event.currentTarget)
    }

    const handleShareClose = () => {
        setShareAnchorEl(null)
    }
    const handleBidHistoryModal = (data) => {
        if ((data.bid_count && data.bid_count !== 0) || (data.nobids && data.nobids !== 0)) {
            console.log(data.id, 'test')
            setHistoryData({
                product_id: data.id,
                end: moment(data.date_closed).isValid() ? data.date_closed : oneYear,
            })
        }
    }
    const openshare = Boolean(shareAnchorEl)
    const id = open ? 'simple-popover' : undefined

    const handleCart = async (id) => {
        if (active) {
            removeFromCart({ id })
        } else addToCart({ id: lotData.id, qty: lotData.qty })
    }

    const hanlderedirection = (id) => {
        if (parseInt(is_registered) == 0 && parseInt(private_auction) == 1) {
            setAlert('Please register auction and continue!', 'error')
            window.scrollTo(0, 0)
        } else {
            history.push({
                pathname: `/productView/${id}`,
                state: { lotid: lotDetails },
            })
        }
    }
    const checkEmursTheme = document.body.className == 'liquidationTwo emursMck'
    const checkThemeClassName = document.body.className == 'liquidationTwo primeBids'
    const isBidDepotProject = document.querySelector('body').classList.contains('BidAuctionDepot')
    const isWizardAuction = document.querySelector('body').classList.contains('wizardTheme')

    return type === 'lot' ? (
        <>
            {lotData ? (
                <div
                    className={`multisellerListCard lotCard ${className}${
                        bidText === 'Winning' && 'winningcard'
                    } 
                ${bidText === 'Outbid' && 'losingcard'}`}
                >
                    <div className="mslImg">
                        {!isBidDepotProject && (
                            <div className="gridDiv">
                                {card_theme == 'liquidationTwo' ? (
                                    <div className="lctnTxtWrpr d-flex align-items-center justify-content-end">
                                        <span className="material-icons">location_on</span>
                                        <span className="location-class">
                                            {lotData?.auction_city}
                                        </span>
                                    </div>
                                ) : null}
                                {lotData.market_status == 'open' ? (
                                    <FavouriteCheckbox
                                        watchlisted={
                                            lotData.watchlistid ||
                                            lotData.watchlisted ||
                                            lotData.isFav
                                        }
                                        project_id={lotData.id}
                                        from={'card'}
                                        viewProduct={viewProduct}
                                        setViewProduct={setViewProduct}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        )}
                        <div className="grid-img-wrap">
                            {global?.storeConfigration?.sold_status?.value == '1' ? (
                                <></>
                            ) : (
                                <>
                                    {lotData.market_status === 'sold' && (
                                        <div className="soldWrapper">
                                            <p>SOLD</p>
                                        </div>
                                    )}
                                    {lotData.market_status === 'closed' && (
                                        <div className="soldWrapper">
                                            <p>UNSOLD</p>
                                        </div>
                                    )}
                                </>
                            )}
                            <LazyLoadImage
                                effect="blur"
                                placeholderSrc="/assets/svg/imageLoading.svg"
                                height="100%"
                                width="100%"
                                src={`${
                                    lotData.content_head1 === '0' || lotData.store_id === 0
                                        ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                                        : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                }${
                                    global.storeConfigration?.image_compression?.value == 1
                                        ? 'thumb_'
                                        : ''
                                }${
                                    (lotData?.lotImages?.length &&
                                        lotData?.lotImages[0]?.file_name) ||
                                    lotData.file_name ||
                                    lotData.avatar_vc
                                }`}
                                // onClick={() =>
                                //     standalonePage
                                //         ? handleRedirectInternal(history, `productView/${lotData.id}`)
                                //         : handleClick(lotData.id)
                                // }
                                onClick={() =>
                                    global.storeDetails?.theme === 9
                                        ? hanlderedirection(lotData.id)
                                        : standalonePage
                                        ? handleRedirectInternal(
                                              history,
                                              `productView/${lotData.id}`,
                                          )
                                        : handleClick(lotData.id)
                                }
                                className="gridImage"
                                onError={addDefaultSrc}
                            />

                            <div className="listVIewShare">
                                {cardTheme === 'liquidationTwo' && (
                                    <>
                                        {isHyperMicroTheme && (
                                            <>
                                                <span
                                                    aria-describedby={id}
                                                    className="material-icons ml-2 share-Icon"
                                                    onClick={handleShareClick}
                                                >
                                                    share
                                                </span>
                                                <Popover
                                                    id={id}
                                                    open={openshare}
                                                    anchorEl={shareAnchorEl}
                                                    onClose={handleShareClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    {console.log(
                                                        selectedProduct,
                                                        'selectedProduct',
                                                    )}
                                                    <div className="pvMiscActions mb-0 p-2">
                                                        <Facebook
                                                            path={`/product/${selectedProduct.id}`}
                                                            title={selectedProduct.title}
                                                        />
                                                        <Whatsapp
                                                            path={`/product/${selectedProduct.id}`}
                                                            title={selectedProduct.title}
                                                        />
                                                        {global.storeConfigration
                                                            ?.disable_twitter_sharing?.value !=
                                                        1 ? (
                                                            <Twitter
                                                                path={`/product/${selectedProduct.id}`}
                                                                title={selectedProduct.title}
                                                            />
                                                        ) : null}
                                                        <Email
                                                            path={`/product/${selectedProduct.id}`}
                                                            subject={selectedProduct.title}
                                                            body={selectedProduct.description}
                                                        />
                                                        {global.storeConfigration
                                                            ?.disable_pinterest_sharing?.value !=
                                                        1 ? (
                                                            <Pinterest
                                                                path={`/product/${selectedProduct.id}`}
                                                                title={selectedProduct.title}
                                                                media={
                                                                    `${
                                                                        selectedProduct.content_head1 ===
                                                                        '0'
                                                                            ? process.env
                                                                                  .REACT_APP_BASE_URL +
                                                                              'uploads/product/'
                                                                            : process.env
                                                                                  .REACT_APP_PRODUCT_IMAGE_URL
                                                                    }` + selectedProduct.avatar
                                                                }
                                                            />
                                                        ) : null}
                                                        <div
                                                            className="cpyToClipDiv"
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <Copy
                                                                text={`/productView/${selectedProduct.id}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </Popover>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            {(cardTheme === 'multiseller' ||
                                cardTheme != 'bidAuction' ||
                                cardTheme === 'liquidationTwo') &&
                            bidStatus === true &&
                            lotData.auction != 0 ? (
                                <>
                                    {bidText == 'Winning' ? (
                                        <p className="wngSts grnTxt">You Are Winning!</p>
                                    ) : bidText == 'Won' ? (
                                        <p className="wngSts grnTxt">You Won!</p>
                                    ) : bidText == 'Outbid' ? (
                                        <p className="wngSts LsgTxt">You Are Losing</p>
                                    ) : bidText == 'Lost' ? (
                                        <p className="wngSts LsgTxt">You Lost</p>
                                    ) : (
                                        <p className="wngSts"></p>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    {isBidDepotProject && cardTheme == 'bidAuction' ? (
                        <div className="mslDesc">
                            <small className="lotIDwithBids">
                                Lot {lotData.lot_number}{' '}
                                <span>{lotData.nobids || lotData.bid_count || 0}</span> Bid(s)
                            </small>
                            <h3
                                className="listTitle"
                                dangerouslySetInnerHTML={{
                                    __html: lotData.title,
                                }}
                                onClick={() =>
                                    standalonePage
                                        ? handleRedirectInternal(
                                              history,
                                              `productView/${lotData.id}`,
                                          )
                                        : handleClick(lotData.id)
                                }
                            />
                            <div className="ConditionBox d-flex align-items-center justify-content-start">
                                <div>
                                    {lotData.retailer_link && lotData.mprice ? (
                                        <h6 className="galleryPrice rtlrPrice">
                                            <span className="prcLabel">Retail Price : </span>
                                            &nbsp;
                                            <span className="prcTxtVal">
                                                {currencyFormat(
                                                    parseFloat(lotData.mprice || 0).toFixed(2),
                                                )}
                                                <CurrencyConverterFormat amount={lotData.mprice} />
                                            </span>
                                        </h6>
                                    ) : null}
                                    <div className="d-flex align-items-center justify-content-start">
                                        <span>Condition : </span>
                                        &nbsp;
                                        <span
                                            className={`prcTxtVal ${
                                                lotData.auctionnotes
                                                    ?.trim()
                                                    ?.toLowerCase()
                                                    ?.includes('new')
                                                    ? 'text-success'
                                                    : lotData.auctionnotes
                                                          ?.trim()
                                                          ?.toLowerCase()
                                                          ?.includes('open box')
                                                    ? 'text-primary'
                                                    : lotData.auctionnotes
                                                          ?.trim()
                                                          ?.toLowerCase()
                                                          ?.includes('damage')
                                                    ? 'text-danger'
                                                    : ''
                                            }`}
                                            title={
                                                lotData.auctionnotes ? lotData.auctionnotes : '-'
                                            }
                                        >
                                            {lotData.auctionnotes ? lotData.auctionnotes : '-'}
                                        </span>
                                    </div>
                                </div>
                                <div className="favWatchlist">
                                    {lotData.market_status == 'open' ? (
                                        <FavouriteCheckbox
                                            watchlisted={
                                                lotData.watchlistid ||
                                                lotData.watchlisted ||
                                                lotData.isFav
                                            }
                                            watchListText
                                            project_id={lotData.id}
                                            data={lotData}
                                            from={'card'}
                                            viewProduct={viewProduct}
                                            setViewProduct={setViewProduct}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <div className="mslMisc1 d-none">
                                {lotData?.auction == 1 || auction_and_buynow == 1 ? (
                                    <>
                                        {lotData.market_status === 'open' ? (
                                            <Timer
                                                date_added={lotData.date_added}
                                                date_closed={lotData.date_closed}
                                                withText={1}
                                                endText={'Ends In'}
                                                startText={'Starts In'}
                                                theme={timerTheme}
                                                product_id={lotData.id}
                                                viewProduct={viewProduct}
                                                setViewProduct={setViewProduct}
                                                daysText=" Days "
                                                hoursText="h "
                                                minutesText="m "
                                                secondsText="s "
                                            />
                                        ) : (
                                            <span className="closedText">Auction Closed</span>
                                        )}
                                    </>
                                ) : null}

                                {lotData.retailer_link && lotData.mprice ? (
                                    <h6 className="col-4 galleryPrice rtlrPrice">
                                        <span className="prcLabel">Retail</span>

                                        <span className="prcTxtVal">
                                            {currencyFormat(
                                                parseFloat(lotData.mprice || 0).toFixed(2),
                                            )}
                                            <CurrencyConverterFormat amount={lotData.mprice} />
                                        </span>
                                    </h6>
                                ) : null}
                            </div>
                            <div className="quckBidcontain">
                                {isAuthenticated ? (
                                    <Bidding
                                        data={lotData}
                                        hideBid={1}
                                        hard="1"
                                        searchView={true}
                                        shortText="Submit Your Bid"
                                        enableNextBid={true}
                                        disableReturnAssurance={true}
                                        nextbidText={`Quick Bid ${
                                            lotData?.next_bid
                                                ? currencyFormat(
                                                      parseFloat(
                                                          lotData?.next_bid ? lotData?.next_bid : 0,
                                                      ).toFixed(2),
                                                  )
                                                : ''
                                        }`}
                                    />
                                ) : null}
                                <Bidding
                                    theme="multiseller"
                                    data={lotData}
                                    hard={global.storeConfigration?.hard_bid?.value || '1'}
                                    isBidAuctionBtn={true}
                                    shortText={
                                        <>
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                className="mr-2"
                                                fill="#49454f"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M2.30031 20.2799L11.9003 10.6799L10.5003 9.25991L9.78031 9.9699C9.6878 10.0626 9.57791 10.1362 9.45694 10.1863C9.33596 10.2365 9.20628 10.2623 9.07531 10.2623C8.94434 10.2623 8.81466 10.2365 8.69369 10.1863C8.57271 10.1362 8.46283 10.0626 8.37031 9.9699L7.66031 9.25991C7.56761 9.16739 7.49406 9.0575 7.44388 8.93653C7.3937 8.81556 7.36787 8.68587 7.36787 8.55491C7.36787 8.42394 7.3937 8.29425 7.44388 8.17328C7.49406 8.05231 7.56761 7.94242 7.66031 7.84991L13.3203 2.18991C13.4128 2.0972 13.5227 2.02365 13.6437 1.97347C13.7647 1.92329 13.8943 1.89746 14.0253 1.89746C14.1563 1.89746 14.286 1.92329 14.4069 1.97347C14.5279 2.02365 14.6378 2.0972 14.7303 2.18991L15.4403 2.89991C15.8303 3.28991 15.8303 3.91991 15.4403 4.30991L14.7303 4.99991L16.1503 6.4299C16.2428 6.3372 16.3527 6.26365 16.4737 6.21347C16.5947 6.16329 16.7243 6.13746 16.8553 6.13746C16.9863 6.13746 17.116 6.16329 17.2369 6.21347C17.3579 6.26365 17.4678 6.3372 17.5603 6.4299C17.9503 6.8199 17.9503 7.45991 17.5603 7.84991L18.9703 9.25991L19.6803 8.54991C20.0703 8.15991 20.7103 8.15991 21.1003 8.54991L21.8003 9.25991C22.1903 9.64991 22.1903 10.2899 21.8003 10.6799L16.1503 16.3299C15.7603 16.7199 15.1203 16.7199 14.7303 16.3299L14.0303 15.6299C13.9354 15.5376 13.8599 15.4273 13.8083 15.3053C13.7568 15.1834 13.7302 15.0523 13.7302 14.9199C13.7302 14.7875 13.7568 14.6565 13.8083 14.5345C13.8599 14.4125 13.9354 14.3022 14.0303 14.2099L14.7303 13.4999L13.3203 12.0899L3.71031 21.6999C3.6178 21.7926 3.50791 21.8662 3.38694 21.9163C3.26596 21.9665 3.13628 21.9923 3.00531 21.9923C2.87434 21.9923 2.74466 21.9665 2.62369 21.9163C2.50271 21.8662 2.39283 21.7926 2.30031 21.6999C1.91031 21.3099 1.91031 20.6699 2.30031 20.2799ZM20.0003 18.9999C20.5307 18.9999 21.0395 19.2106 21.4145 19.5857C21.7896 19.9608 22.0003 20.4695 22.0003 20.9999V21.9999H12.0003V20.9999C12.0003 20.4695 12.211 19.9608 12.5861 19.5857C12.9612 19.2106 13.4699 18.9999 14.0003 18.9999H20.0003Z"
                                                    fill="#49454f"
                                                />
                                            </svg>
                                            MAX BID
                                        </>
                                    }
                                />

                                <div className="favWatchlist d-none">
                                    {lotData.market_status == 'open' ? (
                                        <FavouriteCheckbox
                                            watchlisted={
                                                lotData.watchlistid ||
                                                lotData.watchlisted ||
                                                lotData.isFav
                                            }
                                            project_id={lotData.id}
                                            data={lotData}
                                            from={'card'}
                                            viewProduct={viewProduct}
                                            setViewProduct={setViewProduct}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mslDesc">
                            {card_theme === 'FoodIndustry' ? (
                                <h5 className="hostedBy">
                                    {lotData.product_city ? lotData.product_city : ''}
                                </h5>
                            ) : lotData?.seller_name &&
                              global.storeConfigration?.disable_hosted_by?.value != 1 ? (
                                <h6 className="hostedBy">
                                    {checkEmursTheme ? null : 'Hosted By:'}
                                    {sellerNameFormat(lotData.seller_name)}
                                </h6>
                            ) : null}
                            <h3
                                className="listTitle"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        lotData.bundle_id > 0 && lotData.masterlot == 1
                                            ? lotData.bundle_name
                                            : lotData.title,
                                }}
                                onClick={() =>
                                    standalonePage
                                        ? handleRedirectInternal(
                                              history,
                                              `productView/${lotData.id}`,
                                          )
                                        : handleClick(lotData.id)
                                }
                            />

                            {!lotData.bundle_id &&
                            (parseInt(lotData.lowest) > 0 || parseInt(lotData.highest) > 0) ? (
                                <p className="estimates">
                                    <span>Est:</span>
                                    {`${
                                        ' ' +
                                        currencyFormat(lotData.lowest) +
                                        ' - ' +
                                        currencyFormat(lotData.highest)
                                    }`}
                                </p>
                            ) : (
                                ''
                            )}

                            {showQty === true ? (
                                <p className="estimates">
                                    <span>Qty:</span>
                                    {`${lotData.qty}`}
                                </p>
                            ) : (
                                ' '
                            )}

                            {card_theme === 'liquidationTwo' &&
                            (global.storeConfigration?.condition_field?.value == 1 ||
                                global.storeConfigration?.damaged_field?.value == 1 ||
                                global.storeConfigration?.functional_field?.value == 1 ||
                                global.storeConfigration?.missing_parts_field?.value == 1) ? (
                                <div className="pdtStsInfo mb-2">
                                    <ul>
                                        <li>
                                            <label>Condition:</label>
                                            <Tooltip
                                                title={lotData.condition_field || '-'}
                                                arrow
                                                placement="top"
                                            >
                                                <p
                                                    className={`m-0 cursorDecoy ${
                                                        lotData.condition_field?.includes(
                                                            'Used - Like New',
                                                        )
                                                            ? 'text-Yellow'
                                                            : lotData.condition_field?.includes(
                                                                  'New',
                                                              )
                                                            ? 'text-success'
                                                            : 'text-danger'
                                                    }`}
                                                >
                                                    {lotData.condition_field || '-'}
                                                </p>
                                            </Tooltip>
                                        </li>

                                        <li>
                                            <label>Functional:</label>
                                            <p
                                                className={`m-0 ${
                                                    lotData.functional_field?.includes('Yes')
                                                        ? 'text-success'
                                                        : 'text-danger'
                                                }`}
                                            >
                                                {lotData.functional_field || '-'}
                                            </p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <label>Damaged:</label>
                                            <p
                                                className={`m-0 ${
                                                    lotData.damaged_field?.includes('No')
                                                        ? 'text-success'
                                                        : 'text-danger'
                                                }`}
                                            >
                                                {lotData.damaged_field || '-'}
                                            </p>
                                        </li>

                                        <li>
                                            <label>Missing Parts:</label>
                                            <p
                                                className={`m-0 ${
                                                    lotData.missing_parts_field?.includes('Yes')
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                }`}
                                            >
                                                {lotData.missing_parts_field || '-'}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            ) : (
                                ''
                            )}
                            {global.storeConfigration?.condition_with_color?.value == 1 ? (
                                <h6 className="galleryPrice condition">
                                    <span>Condition: </span>
                                    <span
                                        className={`prcTxtVal ${
                                            lotData.auctionnotes
                                                ?.trim()
                                                ?.toLowerCase()
                                                ?.includes('new')
                                                ? 'badge badge-success text-white'
                                                : lotData.auctionnotes
                                                      ?.trim()
                                                      ?.toLowerCase()
                                                      ?.includes('open box')
                                                ? 'badge badge-primary text-white'
                                                : lotData.auctionnotes
                                                      ?.trim()
                                                      ?.toLowerCase()
                                                      ?.includes('damage')
                                                ? 'badge badge-danger text-white'
                                                : ''
                                        }`}
                                        title={lotData.auctionnotes ? lotData.auctionnotes : '-'}
                                    >
                                        {lotData.auctionnotes ? lotData.auctionnotes : '-'}
                                    </span>
                                </h6>
                            ) : null}

                            {cardTheme == 'liveStock' ? (
                                <>
                                    <div className="lsListInfo">
                                        <h6>
                                            Gender <span>{lotData.content_head3}</span>
                                        </h6>
                                        <h6>
                                            Age <span>{lotData.content_head2}</span>
                                        </h6>
                                        <h6>
                                            Sire <span>{lotData.custom_field_1}</span>
                                        </h6>
                                        <h6>
                                            Dam <span>{lotData.custom_field_2}</span>
                                        </h6>
                                        {/* <h6>
                                            Gait <span>{lotData.content_head2}</span>
                                        </h6> */}
                                        <h6>
                                            Location <span>{lotData.productLocation}</span>
                                        </h6>
                                        {lotData.category_id ? (
                                            <h6>
                                                Type <span>{lotData.category_id}</span>
                                            </h6>
                                        ) : null}
                                    </div>
                                    <div className="lsListMisc">
                                        <h6>
                                            Earnings <span>$ {lotData.modelnumber}</span>
                                        </h6>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {/* <p
                                        className="listDesc"
                                        dangerouslySetInnerHTML={{
                                            __html: lotData.description,
                                        }}
                                    /> */}
                                    {/* <p className="listDescLabel">Description:</p>
                                    <p className="listDesc">{removeHTMLTags(lotData.description)}</p> */}
                                </>
                            )}
                        </div>
                    )}

                    {isBidDepotProject && cardTheme == 'bidAuction' ? (
                        <div className="mslMisc">
                            {/* Auction Timer*/}
                            {lotData?.auction == 1 || auction_and_buynow == 1 ? (
                                <>
                                    {lotData.market_status === 'open' ? (
                                        <Timer
                                            date_added={lotData.date_added}
                                            date_closed={lotData.date_closed}
                                            withText={1}
                                            endText={'Time Remaining'}
                                            startText={'Starts IN' + ':'}
                                            theme={timerTheme}
                                            product_id={lotData.id}
                                            viewProduct={viewProduct}
                                            setViewProduct={setViewProduct}
                                            daysText=" Days "
                                            hoursText="h "
                                            minutesText="m "
                                            secondsText="s "
                                        />
                                    ) : (
                                        <span className="closedText">Auction Closed</span>
                                    )}
                                </>
                            ) : null}

                            {/* CUrrent Bid Status and Count */}
                            {lotData.buynow && lotData.bprice && lotData.auction !== 1 ? (
                                <h6>
                                    {lotData.market_status === 'sold' ? 'Sold' : 'Buy Now'} Price:{' '}
                                    <span>
                                        {currencyFormat(parseFloat(lotData.bprice).toFixed(2))}
                                    </span>
                                </h6>
                            ) : (
                                <div className="lotBidInfo">
                                    {lotData.market_status == 'closed' ? (
                                        <h6 className="msUnsold">
                                            <span>Unsold</span>
                                        </h6>
                                    ) : global.pluginConfiguration?.reverse_auction?.enable &&
                                      parseInt(lotData.content_head5) == 1 ? (
                                        <>
                                            <h6>
                                                {lotData.market_status == 'sold'
                                                    ? 'Sold Bid'
                                                    : 'Start Bid'}
                                                <span>
                                                    {currencyFormat(
                                                        parseFloat(lotData.wprice).toFixed(2),
                                                    )}
                                                </span>
                                            </h6>
                                        </>
                                    ) : (
                                        <>
                                            {lotData.latestbid ? (
                                                <h6>
                                                    {lotData.market_status == 'sold'
                                                        ? 'Sold Bid'
                                                        : 'Current Bid'}
                                                    <span>
                                                        {lotData.latestbid
                                                            ? currencyFormat(
                                                                  parseFloat(
                                                                      lotData.wprice,
                                                                  ).toFixed(2),
                                                              )
                                                            : 'N/A'}
                                                    </span>
                                                </h6>
                                            ) : (
                                                <h6>
                                                    Start Bid
                                                    <span>
                                                        {currencyFormat(
                                                            parseFloat(lotData.sprice).toFixed(2),
                                                        )}
                                                    </span>
                                                </h6>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="mslMisc">
                            {lotData?.auction == 1 || auction_and_buynow == 1 ? (
                                <>
                                    {lotData.market_status === 'open' ? (
                                        <Timer
                                            date_added={lotData.date_added}
                                            date_closed={lotData.date_closed}
                                            withText={1}
                                            endText={'Time left' + ':'}
                                            startText={'Starts in' + ':'}
                                            theme={timerTheme == '' ? '' : 'multiseller'}
                                            product_id={lotData.id}
                                            viewProduct={viewProduct}
                                            setViewProduct={setViewProduct}
                                        />
                                    ) : (
                                        <span className="closedText">Auction Closed</span>
                                        // <div className="timerWrapper">
                                        //         <span className="material-icons-outlined">timer</span>
                                        //     <h6 className="timeLabel">Auction closed</h6>
                                        // </div>
                                    )}
                                    <hr />
                                </>
                            ) : (
                                ''
                            )}

                            {cardTheme === 'liquidationTwo' ? (
                                <>
                                    {lotData.retailer_link && lotData.mprice ? (
                                        <h6
                                            className={`${
                                                checkEmursTheme
                                                    ? 'd-flex justify-content-between'
                                                    : null
                                            } galleryPrice rtlrPrice`}
                                        >
                                            <span className="prcLabel">
                                                Retail Price:{' '}
                                                {checkEmursTheme ? null : (
                                                    <a
                                                        href={lotData.retailer_link}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        (Retailer)
                                                    </a>
                                                )}
                                            </span>
                                            &nbsp;
                                            <span className="prcTxtVal">
                                                {currencyFormat(
                                                    parseFloat(lotData.mprice || 0).toFixed(2),
                                                )}
                                                <CurrencyConverterFormat amount={lotData.mprice} />
                                            </span>
                                        </h6>
                                    ) : (
                                        <h6 className="galleryPrice rtlrPrice"></h6>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}

                            {lotData.buynow && lotData.bprice && lotData.auction !== 1 ? (
                                <h6>
                                    {lotData.market_status === 'sold' ? 'Sold' : 'Buy Now'} Price:{' '}
                                    <span>
                                        {currencyFormat(parseFloat(lotData.bprice).toFixed(2))}
                                    </span>
                                </h6>
                            ) : (
                                <div className="lotBidInfo">
                                    {lotData.market_status == 'closed' ? (
                                        <h6 className="msUnsold">
                                            <span>Unsold</span>
                                        </h6>
                                    ) : global.pluginConfiguration?.reverse_auction?.enable &&
                                      parseInt(lotData.content_head5) == 1 ? (
                                        <>
                                            <h6>
                                                {lotData.market_status == 'sold'
                                                    ? 'Sold Bid'
                                                    : 'Start Bid'}
                                                <span>
                                                    {currencyFormat(
                                                        parseFloat(lotData.wprice).toFixed(2),
                                                    )}
                                                </span>
                                            </h6>

                                            <h6>{lotData.market_status == 'sold' ? 1 : 0} Bids</h6>
                                        </>
                                    ) : (
                                        <>
                                            {checkThemeClassName ? (
                                                <>
                                                    {' '}
                                                    {lotData.latestbid ? (
                                                        <h6>
                                                            {lotData.market_status == 'sold'
                                                                ? 'Sold Bid'
                                                                : 'Current Bid'}
                                                            <span>
                                                                {lotData.latestbid
                                                                    ? currencyFormat(
                                                                          parseFloat(
                                                                              lotData.wprice,
                                                                          ).toFixed(2),
                                                                      )
                                                                    : 'N/A'}
                                                            </span>
                                                        </h6>
                                                    ) : (
                                                        <h6>
                                                            Start Bid
                                                            <span>
                                                                {currencyFormat(
                                                                    parseFloat(
                                                                        lotData.wprice,
                                                                    ).toFixed(2),
                                                                )}
                                                            </span>
                                                        </h6>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {lotData.latestbid ? (
                                                        <h6>
                                                            {lotData.market_status == 'sold'
                                                                ? 'Sold Bid'
                                                                : 'Current Bid'}
                                                            <span>
                                                                {lotData.latestbid
                                                                    ? currencyFormat(
                                                                          parseFloat(
                                                                              lotData.wprice,
                                                                          ).toFixed(2),
                                                                      )
                                                                    : 'N/A'}
                                                            </span>
                                                        </h6>
                                                    ) : (
                                                        <h6>
                                                            Start Bid
                                                            <span>
                                                                {currencyFormat(
                                                                    parseFloat(
                                                                        lotData.sprice,
                                                                    ).toFixed(2),
                                                                )}
                                                            </span>
                                                        </h6>
                                                    )}
                                                </>
                                            )}

                                            <h6
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleBidHistoryModal(lotData)}
                                            >
                                                {lotData.nobids} Bids
                                            </h6>
                                            {checkEmursTheme ? (
                                                <Bidhistory
                                                    modaltitle="Bid history"
                                                    open={Object.keys(historyData).length !== 0}
                                                    value={historyData}
                                                    handleClose={() => setHistoryData({})}
                                                />
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            )}

                            {lotData.buynow && lotData.bprice && lotData.auction !== 1 ? (
                                <>
                                    {cardTheme === 'liquidationTwo' ? (
                                        <>
                                            <PrimaryButton
                                                name={lotData.id}
                                                onClick={() => {
                                                    if (
                                                        lotData.market_status != 'sold' &&
                                                        isAuthenticated
                                                    ) {
                                                        handleCart(lotData.id)
                                                    } else if (
                                                        lotData.market_status != 'sold' &&
                                                        !isAuthenticated
                                                    ) {
                                                        handleRedirectInternal(history, 'login')
                                                    }
                                                }}
                                                label={
                                                    isAuthenticated
                                                        ? lotData.market_status == 'sold'
                                                            ? 'Lot Sold'
                                                            : active === true
                                                            ? 'Added to Cart'
                                                            : `Buy it Now: ${currencyFormat(
                                                                  lotData.bprice,
                                                              )}`
                                                        : 'Login To Buy'
                                                }
                                            />
                                        </>
                                    ) : (
                                        <PrimaryButton
                                            name={lotData.id}
                                            onClick={() => {
                                                if (
                                                    lotData.market_status != 'sold' &&
                                                    isAuthenticated
                                                ) {
                                                    handleClick(lotData.id)
                                                } else if (
                                                    lotData.market_status != 'sold' &&
                                                    !isAuthenticated
                                                ) {
                                                    handleRedirectInternal(history, 'login')
                                                }
                                            }}
                                            label={
                                                isAuthenticated
                                                    ? lotData.market_status == 'sold'
                                                        ? 'Lot Sold'
                                                        : 'Buy Now'
                                                    : 'Login To Buy'
                                            }
                                        />
                                    )}
                                </>
                            ) : lotData?.user_id == user?.id ? (
                                <p style={{ whiteSpace: 'nowrap' }}>
                                    {lotData.buynow
                                        ? 'You cannot buy your own product!'
                                        : 'You cannot bid on your own product!'}
                                </p>
                            ) : (
                                <>
                                    {lotData.market_status != 'open' ||
                                    lotData.productClosed ||
                                    (lotData.date_closed &&
                                        moment(lotData.date_closed).isValid() &&
                                        auctionTimer &&
                                        moment(auctionTimer).isValid() &&
                                        new Date(lotData.date_closed).getTime() -
                                            new Date(auctionTimer).getTime() <
                                            0) ? (
                                        <b>Auction Closed</b>
                                    ) : (
                                        <div className="d-flex flex-wrap subLotLine">
                                            <div
                                                className={`row flex-nowrap slAct ${
                                                    !isAuthenticated && 'notLogged'
                                                }`}
                                            >
                                                {lotData.auction ? (
                                                    global.pluginConfiguration?.reverse_auction
                                                        ?.enable ? (
                                                        <Biddingforwardandreverse
                                                            theme="multiseller"
                                                            data={lotData}
                                                            hard="1"
                                                            shortText={bidBtnLabel}
                                                            is_registered={is_registered}
                                                            private_auction={private_auction}
                                                        />
                                                    ) : (
                                                        <Bidding
                                                            theme="multiseller"
                                                            data={lotData}
                                                            hard={
                                                                global.storeConfigration?.hard_bid
                                                                    ?.value || '1'
                                                            }
                                                            shortText={bidBtnLabel}
                                                        />
                                                    )
                                                ) : null}
                                            </div>
                                        </div>
                                    )}

                                    {cardTheme == 'liquidationTwo' && lotData.auction ? (
                                        <div className="spByNw">
                                            {type == 'buynow' && type != 'Auction' ? (
                                                <div className="spByNw"></div>
                                            ) : (
                                                ((parseInt(lotData.buynow) == 1 &&
                                                    parseInt(lotData.auction) == 0) ||
                                                    (parseInt(lotData.wprice) <=
                                                        parseInt(lotData.bprice) &&
                                                        parseInt(lotData.buynow) == 1 &&
                                                        parseInt(lotData.auction) == 1)) &&
                                                lotData.market_status == 'open' && (
                                                    <>
                                                        {isAuthenticated ? (
                                                            <PrimaryButton
                                                                btnSize={'small'}
                                                                onClick={() =>
                                                                    handleCart(lotData.id)
                                                                }
                                                                disabled={lotData.buynow == 0}
                                                                label={
                                                                    active === true
                                                                        ? 'Added to Cart'
                                                                        : `Buy it Now: ${currencyFormat(
                                                                              lotData.bprice,
                                                                          )}`
                                                                }
                                                            />
                                                        ) : (
                                                            <PrimaryButton
                                                                btnSize={'small'}
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        'login',
                                                                    )
                                                                }
                                                                disabled={lotData.buynow == 0}
                                                                label={`Buy it Now: ${currencyFormat(
                                                                    lotData.bprice,
                                                                )}`}
                                                            />
                                                        )}
                                                    </>
                                                )
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <Button
                                        className="viewMoreBtn"
                                        // onClick={() => handleClick(lotData.id)}

                                        onClick={() =>
                                            global.storeDetails?.theme === 9
                                                ? hanlderedirection(lotData.id)
                                                : standalonePage
                                                ? handleRedirectInternal(
                                                      history,
                                                      `productView/${lotData.id}`,
                                                  )
                                                : handleClick(lotData.id)
                                        }
                                    >
                                        View more details
                                        <span className="material-icons">arrow_right_alt</span>
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                    {isBidDepotProject && bidStatus === true && lotData.auction != 0 ? (
                        <>
                            {bidText == 'Winning' ? (
                                <p className="wngSts grnTxt">You Are Winning!</p>
                            ) : bidText == 'Won' ? (
                                <p className="wngSts grnTxt">You Won!</p>
                            ) : bidText == 'Outbid' ? (
                                <p className="wngSts LsgTxt">You Are Losing</p>
                            ) : bidText == 'Lost' ? (
                                <p className="wngSts LsgTxt">You Lost</p>
                            ) : lotData.market_status == 'open' ? (
                                <p className="wngSts grnTxt">Active</p>
                            ) : lotData.market_status == 'sold' ? (
                                <p className="wngSts LsgTxt">Ended</p>
                            ) : (
                                <p className="wngSts"></p>
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </div>
            ) : null}
        </>
    ) : (
        <>
            {data ? (
                <div className="multisellerListCard">
                    {card_theme === 'liquidationTwo' ||
                        (cardTheme === 'bidAuction' && (
                            <>
                                {matches && (
                                    <div className="mslDesc">
                                        <Link
                                            className="title"
                                            to={details}
                                            target={isFrame ? '_blank' : ''}
                                        >
                                            <h3
                                                className="listTitle"
                                                dangerouslySetInnerHTML={{
                                                    __html: data.title,
                                                }}
                                            />
                                            {isBidDepotProject && bidList == 'List' ? (
                                                <h3>#{data.id}</h3>
                                            ) : null}
                                        </Link>

                                        {isBidDepotProject && bidList == 'List' ? (
                                            <div className="loc d-flex align-items-center justify-content-between">
                                                <div className="col-6">
                                                    <div className="text-wrapper">
                                                        <p>
                                                            <span className="material-icons">
                                                                location_on
                                                            </span>
                                                            {`${data.auction_address} ${data.auction_city}, ${data.auction_state} ${data.auction_zipcode}`}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-6 d-flex align-items-center justify-content-center">
                                                    {data.con_check === 0 ? (
                                                        <SecondaryButton className="6 w-100 mb-2">
                                                            Bidding Open!
                                                        </SecondaryButton>
                                                    ) : (
                                                        <SecondaryButton className="w-100 mb-2 closeBid">
                                                            Bidding Closed!
                                                        </SecondaryButton>
                                                    )}
                                                </div>
                                            </div>
                                        ) : isBidDepotProject ? (
                                            <div>
                                                <p>
                                                    {data.auction_shipping == 1
                                                        ? `Shipping ${
                                                              data.auction_localpickup == 0 &&
                                                              data.auction_shipping == 1
                                                                  ? 'Only'
                                                                  : ''
                                                          }`
                                                        : ''}
                                                    {data.auction_shipping == 1 &&
                                                    data.auction_localpickup == 1
                                                        ? ' and '
                                                        : ''}
                                                    {data.auction_localpickup == 1
                                                        ? `Local Pickup ${
                                                              data.auction_localpickup == 1 &&
                                                              data.auction_shipping == 0
                                                                  ? 'Only'
                                                                  : ''
                                                          } | ${`${data.auction_address} ${data.auction_city}, ${data.auction_state} ${data.auction_zipcode}`}`
                                                        : null}
                                                </p>
                                            </div>
                                        ) : (
                                            <div>
                                                <p>
                                                    {data.auction_shipping == 1
                                                        ? `Shipping ${
                                                              data.auction_localpickup == 0 &&
                                                              data.auction_shipping == 1
                                                                  ? 'Only'
                                                                  : ''
                                                          }`
                                                        : ''}
                                                    {data.auction_shipping == 1 &&
                                                    data.auction_localpickup == 1
                                                        ? ' and '
                                                        : ''}
                                                    {data.auction_localpickup == 1
                                                        ? `Local Pickup ${
                                                              data.auction_localpickup == 1 &&
                                                              data.auction_shipping == 0
                                                                  ? 'Only'
                                                                  : ''
                                                          } | ${data.auction_state}`
                                                        : null}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        ))}

                    {isBidDepotProject && bidList == 'List' ? (
                        <div className="mslCont">
                            {data.file_name && multipleImages != 1 ? (
                                <div className="grid-img-wrap">
                                    <>
                                        <div className="strsEndsDat">
                                            <p className="showEnds">
                                                <small>Ending </small>
                                                {moment(data.date_closed).isValid() ? (
                                                    <>
                                                        {`${moment(data.date_closed)
                                                            .format('ddd MMM')
                                                            .toUpperCase()}
                                                    ${moment(data.date_closed)
                                                        .format('DD')
                                                        .toUpperCase()}`}
                                                        <br />
                                                        {moment(data.date_closed)
                                                            .format('hh:mma')
                                                            .toUpperCase()}
                                                    </>
                                                ) : (
                                                    '-'
                                                )}
                                            </p>
                                        </div>
                                        {data.market_status === 'open' ? (
                                            <>
                                                <Timer
                                                    date_added={data.date_added}
                                                    date_closed={data.date_closed}
                                                    withText={1}
                                                    endText={' '}
                                                    startText={' '}
                                                    theme
                                                    icon={false}
                                                    daysText=" Days "
                                                    hoursText="h "
                                                    minutesText="m "
                                                    noSeconds={true}
                                                />
                                            </>
                                        ) : (
                                            <span className="closedText">Auction Closed</span>
                                            // <div className="timerWrapper">
                                            //     <span className="material-icons-outlined">timer</span>
                                            //     <h6 className="timeLabel">Auction closed</h6>
                                            // </div>
                                        )}
                                    </>
                                </div>
                            ) : (
                                <Skeleton height={220} width={720} />
                            )}
                            <div>
                                <div className="mslMisc">
                                    {card_theme == 'lotsauction' && (
                                        <>
                                            {data.market_status === 'open' ? (
                                                <div className="timer-wrapper li-timer-wrap">
                                                    <div className="li-timer">
                                                        <Timer
                                                            date_added={data.date_added}
                                                            date_closed={data.date_closed}
                                                            withText={1}
                                                            endText={'Ends In'}
                                                            startText="Starts in"
                                                            theme=""
                                                            icon={true}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                // <span className="closedText">Auction Closed</span>
                                                <div className="timerWrapper">
                                                    <span className="material-icons-outlined">
                                                        timer
                                                    </span>
                                                    <h6 className="timeLabel">Auction closed</h6>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {isBidDepotProject &&
                                    cardTheme === 'bidAuction' &&
                                    bidList != 'List' ? (
                                        <>
                                            {data.con_check === 0 ? (
                                                <SecondaryButton className="w-100 mb-2">
                                                    Bidding Open!
                                                </SecondaryButton>
                                            ) : (
                                                <SecondaryButton className="w-100 mb-2 closeBid">
                                                    Bidding Closed!
                                                </SecondaryButton>
                                            )}
                                        </>
                                    ) : card_theme != ('liquidationTwo' && 'lotsauction') &&
                                      !checkThemeClassName &&
                                      !isBidDepotProject ? (
                                        <>
                                            {data.market_status === 'open' ? (
                                                <>
                                                    <Timer
                                                        date_added={data.date_added}
                                                        date_closed={data.date_closed}
                                                        withText={1}
                                                        endText={'Time left' + ':'}
                                                        startText={'Starts in' + ':'}
                                                        theme={theme ? '' : 'multiseller'}
                                                        icon={true}
                                                    />
                                                </>
                                            ) : (
                                                // <span className="closedText">Auction Closed</span>
                                                <div className="timerWrapper">
                                                    <span className="material-icons-outlined">
                                                        timer
                                                    </span>
                                                    <h6 className="timeLabel">Auction closed</h6>
                                                </div>
                                            )}
                                        </>
                                    ) : null}
                                    {/* <p>Lots : {data.lotCount}</p> */}
                                    <PrimaryButton
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                parseInt(
                                                    data?.event_enable ? data.event_enable : 0,
                                                ) == 1
                                                    ? `eventAuction/${data.id}`
                                                    : details,
                                            )
                                        }
                                    >
                                        {card_theme === 'liquidationTwo' && checkThemeClassName
                                            ? 'View all lots'
                                            : isBidDepotProject
                                            ? 'Enter Auction'
                                            : card_theme === 'liquidationTwo'
                                            ? 'EXPLORE AUCTION'
                                            : card_theme === 'lotsauction'
                                            ? 'Bid Now'
                                            : 'Browse Auction Lots'}
                                    </PrimaryButton>
                                    {card_theme === 'liquidationTwo' && !checkThemeClassName && (
                                        <>
                                            {isBidDepotProject &&
                                            card_theme === 'liquidationTwo' ? null : (
                                                <div className="strsEndsDat">
                                                    {global?.storeConfigration?.timer_string_change
                                                        ?.value ? (
                                                        <p className="showEnds">
                                                            Ends on{' '}
                                                            {dateFormatFront(data.date_closed)}
                                                        </p>
                                                    ) : (
                                                        <p className="showEnds">
                                                            Ends {dateFormatFront(data.date_closed)}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="mslDesc">
                                    {card_theme != ('liquidationTwo' && 'lotsauction') && (
                                        <>
                                            {(isBidDepotProject && cardTheme === 'bidAuction') ||
                                            isWizardAuction ? null : (
                                                <>
                                                    {data?.seller_name &&
                                                    global.storeConfigration?.disable_hosted_by
                                                        ?.value != 1 ? (
                                                        <h6 className="hostedBy">
                                                            Hosted By:{' '}
                                                            {sellerNameFormat(data.seller_name)}
                                                        </h6>
                                                    ) : null}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {isBidDepotProject && cardTheme === 'bidAuction' ? (
                                        <>
                                            {matches ? null : (
                                                <Link to={details} target={isFrame ? '_blank' : ''}>
                                                    <h3
                                                        className="listTitle"
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.title,
                                                        }}
                                                    />
                                                </Link>
                                            )}
                                        </>
                                    ) : (
                                        <Link to={details} target={isFrame ? '_blank' : ''}>
                                            <h3
                                                className="listTitle"
                                                dangerouslySetInnerHTML={{
                                                    __html: data.title,
                                                }}
                                            />
                                        </Link>
                                    )}

                                    {card_theme == 'lotsauction' && (
                                        <>
                                            <div className="lots-details mb-2">
                                                <div className="">
                                                    {data.lotCount} item{data.lotCount > 1 && 's'}
                                                </div>

                                                <div className="pick-up">
                                                    <img
                                                        src="/lots-images/shipping-truck.svg"
                                                        alt=""
                                                    />
                                                    Pick up only
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {card_theme === 'liquidationTwo' ||
                                        (cardTheme === 'bidAuction' && (
                                            <>
                                                {matches ? null : (
                                                    <div>
                                                        <p>
                                                            {data.auction_shipping == 1
                                                                ? `Shipping ${
                                                                      data.auction_localpickup ==
                                                                          0 &&
                                                                      data.auction_shipping == 1
                                                                          ? 'Only'
                                                                          : ''
                                                                  }`
                                                                : ''}
                                                            {data.auction_shipping == 1 &&
                                                            data.auction_localpickup == 1
                                                                ? ' and '
                                                                : ''}
                                                            {data.auction_localpickup == 1
                                                                ? `Local Pickup ${
                                                                      data.auction_localpickup ==
                                                                          1 &&
                                                                      data.auction_shipping == 0
                                                                          ? 'Only'
                                                                          : ''
                                                                  }${' |'} ${
                                                                      isBidDepotProject
                                                                          ? `${data.auction_address} ${data.auction_city}, ${data.auction_state} ${data.auction_zipcode}`
                                                                          : data.auction_state
                                                                  }
                                                      `
                                                                : null}
                                                        </p>
                                                    </div>
                                                )}
                                            </>
                                        ))}

                                    {theme == 'HeavyEquipment' && (
                                        <>
                                            <div className="badgeSpan">
                                                <span>Auction Id: #{data.id}</span>
                                                <AddToCalendar data={data} />
                                            </div>
                                            <div className="AuctionDateRange">
                                                <span>
                                                    <b> Start Date:</b>{' '}
                                                    {moment(data.date_added)
                                                        .tz('America/New_York')
                                                        .format('MMM Do YYYY, h:mm a')}
                                                </span>
                                                <span>
                                                    <b>End Date:</b>{' '}
                                                    {moment(data.date_closed)
                                                        .tz('America/New_York')
                                                        .format('MMM Do YYYY, h:mm a')}
                                                </span>
                                            </div>
                                        </>
                                    )}

                                    {isBidDepotProject && cardTheme === 'bidAuction' ? null : (
                                        <>
                                            <label className="mb-1 alv-descp">Description:</label>
                                            {checkThemeClassName ? (
                                                <>
                                                    <ReadMore
                                                        data={data.description}
                                                        className="listDesc"
                                                        limit={30}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p
                                                        className="listDesc"
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.description,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}

                                    {card_theme != 'lotsauction' && !isWizardAuction ? (
                                        <div className="lotImagesGrid">
                                            {data.lot_images &&
                                                data.lot_images.slice(0, 11).map((val, index) => (
                                                    <>
                                                        <LazyLoadImage
                                                            effect="blur"
                                                            placeholderSrc="/assets/svg/imageLoading.svg"
                                                            height="100%"
                                                            width="100%"
                                                            src={`${
                                                                data.store_id === 0
                                                                    ? process.env
                                                                          .REACT_APP_BASE_URL +
                                                                      'uploads/product/'
                                                                    : process.env
                                                                          .REACT_APP_PRODUCT_IMAGE_URL
                                                            }${
                                                                global.storeConfigration
                                                                    ?.image_compression?.value == 1
                                                                    ? 'thumb_'
                                                                    : ''
                                                            }${val?.file_name}`}
                                                            key={index}
                                                            onError={addDefaultSrc}
                                                            onClick={() => {
                                                                handleLotClick(val.id)
                                                            }}
                                                        />
                                                    </>
                                                ))}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {' '}
                            {data.file_name && multipleImages != 1 ? (
                                <div className="grid-img-wrap">
                                    {checkThemeClassName ? (
                                        <div className="multi-timer-card">
                                            <div className="strsEndsDat">
                                                <p className="showEnds">Ending</p>
                                                <p className="date">
                                                    {dateFromatFrontDayMonth(data.date_closed)}
                                                </p>
                                                <div className="timer">
                                                    {data.market_status === 'open' ? (
                                                        <>
                                                            <Timer
                                                                date_added={data.date_added}
                                                                date_closed={data.date_closed}
                                                                theme={''}
                                                                endText={' '}
                                                                startText={' '}
                                                            />
                                                        </>
                                                    ) : (
                                                        <h6 className="timeLabel">
                                                            Auction closed
                                                        </h6>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : isBidDepotProject && cardTheme === 'bidAuction' ? (
                                        <>
                                            <div className="strsEndsDat">
                                                <p className="showEnds">
                                                    <small>Ending </small>
                                                    {moment(data.date_closed).isValid() ? (
                                                        <>
                                                            {moment(data.date_closed).format(
                                                                'dddd',
                                                            )}
                                                            <br />
                                                            {`${moment(data.date_closed)
                                                                .format('MMM')
                                                                .toUpperCase()}. 
                                                    ${moment(data.date_closed)
                                                        .format('DD')
                                                        .toUpperCase()}`}
                                                            <br />
                                                            {moment(data.date_closed).format(
                                                                'hh:mm a',
                                                            )}
                                                        </>
                                                    ) : (
                                                        '-'
                                                    )}
                                                </p>
                                            </div>
                                            {data.market_status === 'open' ? (
                                                <>
                                                    <Timer
                                                        date_added={data.date_added}
                                                        date_closed={data.date_closed}
                                                        withText={1}
                                                        endText={' '}
                                                        startText={' '}
                                                        theme
                                                        icon={false}
                                                        daysText=" Days "
                                                        hoursText="h "
                                                        minutesText="m "
                                                        secondsText="s "
                                                    />
                                                </>
                                            ) : (
                                                <span className="closedText">Auction Closed</span>
                                                // <div className="timerWrapper">
                                                //     <span className="material-icons-outlined">timer</span>
                                                //     <h6 className="timeLabel">Auction closed</h6>
                                                // </div>
                                            )}
                                        </>
                                    ) : (
                                        <Link to={details} target={isFrame ? '_blank' : ''}>
                                            <LazyLoadImage
                                                effect="blur"
                                                placeholderSrc="/assets/svg/imageLoading.svg"
                                                height="100%"
                                                width="100%"
                                                src={`${
                                                    data.store_id === 0
                                                        ? process.env.REACT_APP_BASE_URL +
                                                          'uploads/product/'
                                                        : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                                }${
                                                    global.storeConfigration?.image_compression
                                                        ?.value == 1
                                                        ? 'thumb_'
                                                        : ''
                                                }${data.file_name}`}
                                                className="gridImage"
                                                onError={addDefaultSrc}
                                            />
                                            {card_theme != 'lotsauction' && (
                                                <span
                                                    className={`badge ${
                                                        data.con_check ? 'live' : 'timed'
                                                    }`}
                                                >
                                                    {data.con_check ? 'Live' : 'Timed'}
                                                </span>
                                            )}
                                        </Link>
                                    )}
                                    {isWizardAuction && (
                                        <div className="timeWrp">
                                            {data.market_status === 'open' ? (
                                                <>
                                                    <Timer
                                                        date_added={data.date_added}
                                                        date_closed={data.date_closed}
                                                        withText={1}
                                                        endText={'Time Left'}
                                                        startText={'Starts In'}
                                                        showDateNumber={2}
                                                        theme={'wizard'}
                                                        daysText=" days "
                                                        hoursText=" hours"
                                                        minutesText=" minutes"
                                                        secondsText=" seconds"
                                                    />
                                                </>
                                            ) : (
                                                <div className="timerWrapper">
                                                    <span className="material-icons-outlined">
                                                        timer
                                                    </span>
                                                    <h6 className="timeLabel">Auction closed</h6>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ) : card_theme == 'lotsauction' ? (
                                <div className="grid-img-wrap grid-img-wrap_multi">
                                    {data.lot_images.length < 4
                                        ? Array(4 - data.lot_images.length)
                                              .fill({ file_name: '' })
                                              .concat(...data.lot_images.reverse())
                                              .reverse()
                                              .map(
                                                  (val, index) =>
                                                      index < 4 && (
                                                          <Link
                                                              to={details}
                                                              className="qurantHref"
                                                              target={isFrame ? '_blank' : ''}
                                                          >
                                                              <LazyLoadImage
                                                                  src={getImageurl(
                                                                      data.store_id.toString(),
                                                                      val.file_name || '',
                                                                  )}
                                                                  effect="blur"
                                                                  placeholderSrc="/assets/svg/imageLoading.svg"
                                                                  height="100%"
                                                                  width="100%"
                                                                  className="gridImage"
                                                                  onError={addDefaultSrc}
                                                                  alt={data.title}
                                                              />
                                                          </Link>
                                                      ),
                                              )
                                        : data.lot_images.map(
                                              (val, index) =>
                                                  index < 4 && (
                                                      <Link
                                                          to={details}
                                                          className="qurantHref"
                                                          target={isFrame ? '_blank' : ''}
                                                      >
                                                          <LazyLoadImage
                                                              src={getImageurl(
                                                                  data.store_id.toString(),
                                                                  val.file_name || '',
                                                              )}
                                                              effect="blur"
                                                              placeholderSrc="/assets/svg/imageLoading.svg"
                                                              height="100%"
                                                              width="100%"
                                                              className="gridImage"
                                                              onError={addDefaultSrc}
                                                              alt={data.title}
                                                          />
                                                      </Link>
                                                  ),
                                          )}
                                </div>
                            ) : (
                                <Skeleton height={220} width={720} />
                            )}
                            <div className="mslDesc">
                                {card_theme != ('liquidationTwo' && 'lotsauction') && (
                                    <>
                                        {(isBidDepotProject && cardTheme === 'bidAuction') ||
                                        isWizardAuction ? null : (
                                            <>
                                                {data?.seller_name &&
                                                global.storeConfigration?.disable_hosted_by
                                                    ?.value != 1 ? (
                                                    <h6 className="hostedBy">
                                                        Hosted By:{' '}
                                                        {sellerNameFormat(data.seller_name)}
                                                    </h6>
                                                ) : null}
                                            </>
                                        )}
                                    </>
                                )}
                                {isBidDepotProject && cardTheme === 'bidAuction' ? (
                                    <>
                                        {matches ? null : (
                                            <Link to={details} target={isFrame ? '_blank' : ''}>
                                                <h3
                                                    className="listTitle"
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.title,
                                                    }}
                                                />
                                            </Link>
                                        )}
                                    </>
                                ) : (
                                    <Link to={details} target={isFrame ? '_blank' : ''}>
                                        <h3
                                            className="listTitle"
                                            dangerouslySetInnerHTML={{
                                                __html: data.title,
                                            }}
                                        />
                                    </Link>
                                )}

                                {card_theme == 'lotsauction' && (
                                    <>
                                        <div className="lots-details mb-2">
                                            <div className="">
                                                {data.lotCount} item{data.lotCount > 1 && 's'}
                                            </div>

                                            <div className="pick-up">
                                                <img src="/lots-images/shipping-truck.svg" alt="" />
                                                Pick up only
                                            </div>
                                        </div>
                                    </>
                                )}

                                {card_theme === 'liquidationTwo' ||
                                    (cardTheme === 'bidAuction' && (
                                        <>
                                            {matches ? null : (
                                                <div>
                                                    <p>
                                                        {data.auction_shipping == 1
                                                            ? `Shipping ${
                                                                  data.auction_localpickup == 0 &&
                                                                  data.auction_shipping == 1
                                                                      ? 'Only'
                                                                      : ''
                                                              }`
                                                            : ''}
                                                        {data.auction_shipping == 1 &&
                                                        data.auction_localpickup == 1
                                                            ? ' and '
                                                            : ''}
                                                        {data.auction_localpickup == 1
                                                            ? `Local Pickup ${
                                                                  data.auction_localpickup == 1 &&
                                                                  data.auction_shipping == 0
                                                                      ? 'Only'
                                                                      : ''
                                                              }${' |'} ${
                                                                  isBidDepotProject
                                                                      ? `${data.auction_address} ${data.auction_city}, ${data.auction_state} ${data.auction_zipcode}`
                                                                      : data.auction_state
                                                              }
                                                      `
                                                            : null}
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    ))}

                                {theme == 'HeavyEquipment' && (
                                    <>
                                        <div className="badgeSpan">
                                            <span>Auction Id: #{data.id}</span>
                                            <AddToCalendar data={data} />
                                        </div>
                                        <div className="AuctionDateRange">
                                            <span>
                                                <b> Start Date:</b>{' '}
                                                {moment(data.date_added)
                                                    .tz('America/New_York')
                                                    .format('MMM Do YYYY, h:mm a')}
                                            </span>
                                            <span>
                                                <b>End Date:</b>{' '}
                                                {moment(data.date_closed)
                                                    .tz('America/New_York')
                                                    .format('MMM Do YYYY, h:mm a')}
                                            </span>
                                        </div>
                                    </>
                                )}

                                {isBidDepotProject ||
                                isWizardAuction ||
                                cardTheme === 'bidAuction' ? null : (
                                    <>
                                        <label className="mb-1 alv-descp">Description:</label>
                                        {checkThemeClassName ? (
                                            <>
                                                <ReadMore
                                                    data={data.description}
                                                    className="listDesc"
                                                    limit={30}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <p
                                                    className="listDesc"
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.description,
                                                    }}
                                                />
                                            </>
                                        )}
                                    </>
                                )}

                                {isWizardAuction && (
                                    <div className="wizDesc">
                                        <label className="">Description:</label>
                                        &nbsp;
                                        <p
                                            className="listDesc"
                                            dangerouslySetInnerHTML={{
                                                __html: data.description,
                                            }}
                                        />
                                    </div>
                                )}

                                {card_theme != 'lotsauction' && !isWizardAuction ? (
                                    <div className="lotImagesGrid">
                                        {data.lot_images &&
                                            data.lot_images.slice(0, 10).map((val, index) => (
                                                <>
                                                    <LazyLoadImage
                                                        effect="blur"
                                                        placeholderSrc="/assets/svg/imageLoading.svg"
                                                        height="100%"
                                                        width="100%"
                                                        src={`${
                                                            data.store_id === 0
                                                                ? process.env.REACT_APP_BASE_URL +
                                                                  'uploads/product/'
                                                                : process.env
                                                                      .REACT_APP_PRODUCT_IMAGE_URL
                                                        }${
                                                            global.storeConfigration
                                                                ?.image_compression?.value == 1
                                                                ? 'thumb_'
                                                                : ''
                                                        }${val?.file_name}`}
                                                        key={index}
                                                        onError={addDefaultSrc}
                                                        onClick={() => {
                                                            handleLotClick(val.id)
                                                        }}
                                                    />
                                                </>
                                            ))}
                                    </div>
                                ) : null}
                            </div>
                            <div className="mslMisc">
                                {card_theme == 'lotsauction' && (
                                    <>
                                        {data.market_status === 'open' ? (
                                            <div className="timer-wrapper li-timer-wrap">
                                                <div className="li-timer">
                                                    <Timer
                                                        date_added={data.date_added}
                                                        date_closed={data.date_closed}
                                                        withText={1}
                                                        endText={'Ends In'}
                                                        startText="Starts in"
                                                        theme=""
                                                        icon={true}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            // <span className="closedText">Auction Closed</span>
                                            <div className="timerWrapper">
                                                <span className="material-icons-outlined">
                                                    timer
                                                </span>
                                                <h6 className="timeLabel">Auction closed</h6>
                                            </div>
                                        )}
                                    </>
                                )}
                                {isBidDepotProject &&
                                cardTheme === 'bidAuction' &&
                                bidList != 'List' ? (
                                    <>
                                        {data.con_check === 0 ? (
                                            <SecondaryButton className="w-100 mb-2">
                                                Bidding Open!
                                            </SecondaryButton>
                                        ) : (
                                            <SecondaryButton className="w-100 mb-2 closeBid">
                                                Bidding Closed!
                                            </SecondaryButton>
                                        )}
                                    </>
                                ) : card_theme != ('liquidationTwo' && 'lotsauction') &&
                                  !checkThemeClassName &&
                                  !isBidDepotProject &&
                                  !isWizardAuction ? (
                                    <>
                                        {data.market_status === 'open' ? (
                                            <>
                                                <Timer
                                                    date_added={data.date_added}
                                                    date_closed={data.date_closed}
                                                    withText={1}
                                                    endText={'Time left' + ':'}
                                                    startText={'Starts in' + ':'}
                                                    theme={theme ? '' : 'multiseller'}
                                                    icon={true}
                                                />
                                            </>
                                        ) : (
                                            // <span className="closedText">Auction Closed</span>
                                            <div className="timerWrapper">
                                                <span className="material-icons-outlined">
                                                    timer
                                                </span>
                                                <h6 className="timeLabel">Auction closed</h6>
                                            </div>
                                        )}
                                    </>
                                ) : null}
                                {/* <p>Lots : {data.lotCount}</p> */}
                                <PrimaryButton
                                    onClick={() =>
                                        handleRedirectInternal(
                                            history,
                                            parseInt(data?.event_enable ? data.event_enable : 0) ==
                                                1
                                                ? `eventAuction/${data.id}`
                                                : details,
                                        )
                                    }
                                >
                                    {card_theme === 'liquidationTwo' && checkThemeClassName
                                        ? 'View all lots'
                                        : isBidDepotProject
                                        ? 'Enter Auction'
                                        : isWizardAuction
                                        ? 'View All Lots'
                                        : card_theme === 'liquidationTwo'
                                        ? 'EXPLORE AUCTION'
                                        : card_theme === 'lotsauction'
                                        ? 'Bid Now'
                                        : 'Browse Auction Lots'}
                                </PrimaryButton>
                                {card_theme === 'liquidationTwo' && !checkThemeClassName && (
                                    <>
                                        {isBidDepotProject ||
                                        isWizardAuction ||
                                        card_theme === 'liquidationTwo' ? null : (
                                            <div className="strsEndsDat">
                                                {global?.storeConfigration?.timer_string_change
                                                    ?.value ? (
                                                    <p className="showEnds">
                                                        Ends on {dateFormatFront(data.date_closed)}
                                                    </p>
                                                ) : (
                                                    <p className="showEnds">
                                                        Ends {dateFormatFront(data.date_closed)}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                                {card_theme === 'lotsauction' && (
                                    <div className="bids-buttons-wrap mt-4">
                                        <Button
                                            className="w-100"
                                            onClick={(e) => {
                                                handleRedirectInternal(
                                                    history,
                                                    global.storeDetails?.theme == 19
                                                        ? details.replace('cat=0', 'cat=1')
                                                        : details + '?cat=1',
                                                )
                                            }}
                                        >
                                            Categories
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {/* Commomted Codes */}
                    {/* <Link
                        to={details}
                        className="qurantHref"
                        target={isFrame ? '_blank' : ''}
                        >
                        <LazyLoadImage
                            src={getImageurl(
                                data.store_id.toString(),
                                data?.lot_images.length > 0
                                    ? data.lot_images[0].file_name
                                    : '',
                            )}
                            effect="blur"
                            placeholderSrc="/assets/svg/imageLoading.svg"
                            height="100%"
                            width="100%"
                            className="gridImage"
                            onError={addDefaultSrc}
                            alt={data.title}
                        />
                    </Link>
                    <Link
                        to={details}
                        className="qurantHref"
                        target={isFrame ? '_blank' : ''}
                    >
                        <LazyLoadImage
                            src={getImageurl(
                                data.store_id.toString(),
                                data?.lot_images.length > 1
                                    ? data.lot_images[1].file_name
                                    : '',
                            )}
                            effect="blur"
                            placeholderSrc="/assets/svg/imageLoading.svg"
                            height="100%"
                            width="100%"
                            className="gridImage"
                            onError={addDefaultSrc}
                            alt={data.title}
                        />
                    </Link>
                    <Link
                        to={details}
                        className="qurantHref"
                        target={isFrame ? '_blank' : ''}
                    >
                        <LazyLoadImage
                            src={getImageurl(
                                data.store_id.toString(),
                                data?.lot_images.length > 2
                                    ? data?.lot_images[2]?.file_name
                                    : '',
                            )}
                            effect="blur"
                            placeholderSrc="/assets/svg/imageLoading.svg"
                            height="100%"
                            width="100%"
                            className="gridImage"
                            onError={addDefaultSrc}
                            alt={data.title}
                        />
                    </Link>
                    <Link
                        to={details}
                        className="qurantHref"
                        target={isFrame ? '_blank' : ''}
                    >
                        <LazyLoadImage
                            src={getImageurl(
                                data.store_id.toString(),
                                data?.lot_images.length > 3
                                    ? data?.lot_images[3]?.file_name
                                    : '',
                            )}
                            effect="blur"
                            placeholderSrc="/assets/svg/imageLoading.svg"
                            height="100%"
                            width="100%"
                            className="gridImage"
                            onError={addDefaultSrc}
                            alt={data.title}
                        />
                    </Link> */}

                    {/* {data.file_name ? (
                        <div className="grid-img-wrap">
                            <Link
                                to={details}
                                className="qurantHref"
                                target={isFrame ? '_blank' : ''}
                            >
                                <LazyLoadImage
                                    src={getImageurl(
                                        data.store_id.toString(),
                                        data?.lot_images.length > 0
                                            ? data.lot_images[0].file_name
                                            : '',
                                    )}
                                    effect="blur"
                                    placeholderSrc="/assets/svg/imageLoading.svg"
                                    height="100%"
                                    width="100%"
                                    className="gridImage"
                                    onError={addDefaultSrc}
                                    alt={data.title}
                                />
                            </Link>
                            <Link
                                to={details}
                                className="qurantHref"
                                target={isFrame ? '_blank' : ''}
                            >
                                <LazyLoadImage
                                    src={getImageurl(
                                        data.store_id.toString(),
                                        data.lot_images.length > 1
                                            ? data.lot_images[1].file_name
                                            : '',
                                    )}
                                    effect="blur"
                                    placeholderSrc="/assets/svg/imageLoading.svg"
                                    height="100%"
                                    width="100%"
                                    className="gridImage"
                                    onError={addDefaultSrc}
                                    alt={data.title}
                                />
                            </Link>
                            <Link
                                to={details}
                                className="qurantHref"
                                target={isFrame ? '_blank' : ''}
                            >
                                <LazyLoadImage
                                    src={getImageurl(
                                        data.store_id.toString(),

                                        data.lot_images.length > 2
                                            ? data.lot_images[2].file_name
                                            : '',
                                    )}
                                    effect="blur"
                                    placeholderSrc="/assets/svg/imageLoading.svg"
                                    height="100%"
                                    width="100%"
                                    className="gridImage"
                                    onError={addDefaultSrc}
                                    alt={data.title}
                                />
                            </Link>
                            <Link
                                to={details}
                                className="qurantHref"
                                target={isFrame ? '_blank' : ''}
                            >
                                <LazyLoadImage
                                    src={getImageurl(
                                        data.store_id.toString(),
                                        data.lot_images.length > 3
                                            ? data.lot_images[3].file_name
                                            : '',
                                    )}
                                    effect="blur"
                                    placeholderSrc="/assets/svg/imageLoading.svg"
                                    height="100%"
                                    width="100%"
                                    className="gridImage"
                                    onError={addDefaultSrc}
                                    alt={data.title}
                                />
                            </Link>
                        </div>
                    ) : (
                        <Skeleton height={280} width={220} />
                    )} */}
                </div>
            ) : null}
        </>
    )
}

export default MultisellerListCard
