import React, { useState, useEffect } from 'react'
import Popup from '../../Product/components/organisms/Popup'
import ContactUs from '../../Themes/LotsAuction/ContactUs'
import { truncate } from 'lodash'
const AskQuestion = (props) => {
    return (
        <Popup {...props} size="md" modalClass="lots-aq-modal" modaltitle="Ask A Question">
            <ContactUs
                hideComp={true}
                blockRedirection={global.storeDetails?.theme === 19 ? true : false}
                handleClose={props.handleClose}
                removePhone={true}
                selectedProduct={props.selectedProduct}
            />
        </Popup>
    )
}

export default AskQuestion
