import React, { useContext, useEffect, useState } from 'react'
import { mapData, converDate } from '../../../../Product/common/components'
import PrimaryButton from '../../../../Product/components/atoms/PrimaryButton'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomDialog from '../../organisms/Dialog'

import StripeCardContext from '../../../../Product/context/stripe/card/cardContext'
import StripeCustomerContext from '../../../../Product/context/stripe/customer/customerContext'
import AuthContext from '../../../../Product/context/auth/authContext'
// import CustomLoader from './../../molecules/Loaders/CustomLoader'
import AlertContext from '../../../../Product/context/alert/alertContext'
import Popup from '../../organisms/Popup'
import axios from 'axios'
import { swapCountry } from '../../../../utils/commonFunctions'
import { useTranslation } from 'react-i18next'
import pluginContext from '../../../context/plugin/pluginContext'
import Loader from '../../../../assets/loader'

function CardDetails(props) {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { securityScanner, buyerNotification } = useContext(pluginContext)

    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])

    const { setAlert } = alertContext
    const { user } = authContext
    const {
        get_all_card_details,
        createStripeCard,
        createCard,
        createStripeCardWithCustomer,
        deleteStripeCard,
        deleteCard,
        updateStripeCard,
        responseStatus,
        clearResponse,
    } = useContext(StripeCardContext)

    const {
        updateStripeCustomer,
        getStripeCustomer,
        customer_details,
        responseStatus: responseStatusStripeCustomer,
    } = useContext(StripeCustomerContext)

    const [disabled, setDisabled] = useState(false)
    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }
    const { t } = useTranslation()
    const [reload, setReload] = useState(false)
    const creditCardYearOptions = []
    const [isLoading, setIsLoading] = useState(false)
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }
    const creditCardMonthOptions = []
    for (let month = parseInt(1); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: monthValue,
            show: monthValue,
        })
    }

    const validationCard = Yup.object({
        card_number: Yup.string()
            .min(15, 'Invalid credit card number!')
            .max(18, 'Invalid credit card number!')
            .required(t('required') + '!'),
        card_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s]*$/g, 'The special characters and numbers are not allowed!')
            .required(t('required') + '!'),
        card_exp_month: Yup.string().required(t('required') + '!'),
        card_exp_year: Yup.string().required(t('required') + '!'),
        card_cvc: Yup.string()
            .matches('^[0-9]{3,4}$', 'Invalid CVV!')
            .required(t('required') + '!'),
        card_address_line1: Yup.string()
            .trim()
            .required(t('required') + '!'),
        card_address_line2: Yup.string(),
        card_address_city: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'Only characters allowed')
            .required(t('required') + '!'),
        card_address_state: Yup.string().required(t('required') + '!'),
        card_address_country: Yup.string().required(t('required') + '!'),
        card_address_zip: Yup.string().when('card_address_country', {
            is: '38',
            then: Yup.string()
                .required(t('required') + '!')
                .min(5, 'Minimum 5 characters')
                .max(6, 'Minimum 7 characters'),
            otherwise: Yup.string()
                .required(t('required') + '!')
                .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
                .min(5, 'Minimum 5 characters')
                .max(6, 'Minimum 7 characters'),
        }),
        // phone: Yup.string().matches(phonenumber, 'Phone number is not valid'),
    })

    const cardFormik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_AUCTIONPAYID,
            customer_id: user ? user.card_paymentid : '',
            card_number: '',
            card_name: '',
            card_exp_month: '',
            card_exp_year: '',
            card_cvc: '',
            cvv: '',
            card_address_line1: '',
            card_address_line2: '',
            card_address_city: '',
            card_address_state: '',
            card_address_zip: '',
            card_address_country: '' || global.defaultCountry == 'US' ? 224 : '',
            is_primary: '',
            user_id: '',
            id: '',
            card_token: true,
        },
        validationSchema: validationCard,
        onSubmit: (values) => {
            setDisabled(true)
            props.loader && setIsLoading(true)
            var send_params = values
            var country_name
            if (send_params.card_address_country && countryList.length > 0) {
                var card_country = countryList.filter(
                    (val) => parseInt(val.location_id) == parseInt(values.card_address_country),
                )
                if (card_country.length > 0) {
                    // send_params.card_address_country = card_country[0].name;
                    country_name = card_country[0].name
                }
            }
            if (global.storeConfigration?.enable_cardknox?.value == 1) {
                createCard({
                    site_id: global.storeDetails?.site_id,
                    user_id: user.id,
                    email: user.email,
                    card_name: values.card_name,
                    card_number: values.card_number,
                    card_expiry_month: values.card_exp_month,
                    card_expiry_year: values.card_exp_year.toString().slice(-2),
                    card_cvc: values.card_cvc,
                    card_address_line1: values.card_address_line1,
                    card_address_zip: values.card_address_zip,
                    customer_id: user.card_paymentid,
                })
            } else {
                createStripeCard({
                    ...send_params,
                    country_name,
                })
            }
        },
    })
    const validationupdateCard = Yup.object({
        exp_month: Yup.string().required(t('required') + '!'),
        exp_year: Yup.string().required(t('required') + '!'),
    })

    const updateCardformik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_AUCTIONPAYID,
            customer_id: user ? user.card_paymentid : '',
            source_id: '',
            exp_month: '',
            exp_year: '',
        },
        validationSchema: validationupdateCard,
        onSubmit: (values) => {
            setDisabled(true)
            props.loader && setIsLoading(true)
            updateStripeCard(values)
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            data: '',
            status: '',
        },
        onSubmit: (values) => {
            setDisabled(true)
            props.loader && setIsLoading(true)
            const dataValue = values
            if (dataValue.status === 'delete') {
                if (global.storeConfigration?.enable_cardknox?.value == 1) {
                    deleteCard({
                        payment_method_id: props.data.data.PaymentMethodId,
                    })
                } else {
                    deleteStripeCard({
                        account_id: process.env.REACT_APP_AUCTIONPAYID,
                        customer_id: user.card_paymentid,
                        source_id: props.data.data.id,
                    })
                }

                setChangeStatus(false)
                props.function(false, 'new', null)
            } else if (dataValue.status === 'default') {
                updateStripeCustomer({
                    account_id: process.env.REACT_APP_AUCTIONPAYID,
                    customer_id: user.card_paymentid,
                    default_source: props.data.data.id,
                })
                setChangeStatus(false)
                props.function(false, 'new', null)
            }
        },
    })

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                setChangeStatus(true)
            } else if (props.data.status === 'default') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                setChangeStatus(true)
            } else if (props.data.status === 'update') {
                updateCardformik.values.source_id = props.data.data.id
                updateCardformik.values.customer_id = props.data.data.customer
                updateCardformik.values.exp_month = ('0' + props.data.data.exp_month).slice(-2)
                updateCardformik.values.exp_year = props.data.data.exp_year
                setReload(!reload)
            }
            setDisabled(false)
            props.loader && setIsLoading(false)
        }
    }, [props.data.data])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'card_source_create') {
                if (responseStatus.status === 'success') {
                    securityScanner(
                        {
                            user_id: user?.id,
                            first_name: user?.first_name,
                            last_name: user?.last_name,
                            email: user?.email,
                            address: cardFormik.values.card_address_line1,
                            city: cardFormik.values.card_address_city,
                            state: cardFormik.values.card_address_state,
                            country: cardFormik.values.card_address_country,
                            zipcode: cardFormik.values.card_address_zip,
                            type: 3,
                            last_4: cardFormik.values.card_number.toString().slice(-4),
                        },
                        'register',
                    )
                    buyerNotification({ content: 'Buyer Add Credit Card!' })
                    cardFormik.resetForm()
                }
            }
            if (responseStatus.from === 'card_source_update') {
                if (responseStatus.status === 'success') {
                    buyerNotification({ content: 'Buyer Update Credit Card!' })
                }
            }
            if (responseStatus.from === 'card_source_delete') {
                if (responseStatus.status === 'success') {
                    buyerNotification({ content: 'Buyer Delete Credit Card!' })
                }
            }
            setDisabled(false)
            props.loader && setIsLoading(false)
            clearResponse()
        }
    }, [responseStatus])

    useEffect(() => {
        if (responseStatusStripeCustomer) {
            if (responseStatusStripeCustomer.status === 'success') {
                if (responseStatusStripeCustomer.from === 'update') {
                    setDisabled(false)
                    props.loader && setIsLoading(false)
                }
            }
        }
    }, [responseStatusStripeCustomer])

    const cardUpdate = {
        formik: updateCardformik,
        data: [
            {
                label: t('expiry_month'),
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: t('expiry_year'),
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'exp_year',
                options: creditCardYearOptions,
            },
        ],
    }

    const cardDetails = {
        formik: cardFormik,
        data: [
            {
                label: t('card_holder_name'),
                type: 'text',
                placeholder: 'Enter your card holder name',
                class: 'col-12',
                name: 'card_name',
            },
            {
                label: t('card_number'),
                type: 'number',
                placeholder: 'Enter your card number',
                class: 'col-12',
                name: 'card_number',
            },
            {
                label: t('expiry_month'),
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: t('expiry_year'),
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'card_exp_year',
                options: creditCardYearOptions,
            },
            {
                label: t('cvv'),
                type: 'text',
                placeholder: 'Enter your CVV',
                class: 'col-12',
                name: 'card_cvc',
            },
            {
                label: t('address_line_1'),
                type: 'text',
                placeholder: 'Enter address line1',
                class: 'col-12',
                name: 'card_address_line1',
            },
            {
                label: t('address_line_2'),
                type: 'text',
                placeholder: 'Enter address line2',
                class: 'col-12',
                name: 'card_address_line2',
            },
            {
                label: t('city'),
                type: 'text',
                placeholder: 'Enter city',
                class: 'col-12',
                name: 'card_address_city',
            },
            // {
            //     label: 'Card address state',
            //     type: 'text',
            //     placeholder: 'Enter card address state',
            //     class: 'col-12',
            //     name: 'card_address_state',
            // },
            // {
            //     label: 'Card address country',
            //     type: 'text',
            //     placeholder: 'Enter card address country',
            //     class: 'col-12',
            //     name: 'card_address_country',
            // },
            {
                label: t('country'),
                placeholder: t('select_country'),
                class: 'col-12',
                type: 'select',
                options: swapCountry(countryList).map((country) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = country.name
                    busCategoryChanged.value = country.location_id
                    return busCategoryChanged
                }),
                name: 'card_address_country',
            },
            {
                label: `${cardFormik.values.card_address_country == 38 ? 'Provinces' : t('state')}`,
                type: 'select',
                placeholder: `${
                    cardFormik.values.card_address_country == 38
                        ? 'Select provinces'
                        : t('select_state')
                }`,
                class: 'col-12',
                options: stateList.map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = buscat.name
                    busCategoryChanged.value = buscat.name
                    return busCategoryChanged
                }),
                name: 'card_address_state',
            },
            {
                label: `${
                    cardFormik.values.card_address_country == 38 ? 'Postal code' : t('Zip_code')
                }`,
                type: 'text',
                placeholder: `Enter ${
                    cardFormik.values.card_address_country == 38 ? 'postal code' : 'zip'
                }`,
                class: 'col-12',
                name: 'card_address_zip',
            },
        ],
    }
    useEffect(() => {
        if (user) {
            cardFormik.values.customer_id = user.card_paymentid
            updateCardformik.values.customer_id = user.card_paymentid
        }
    }, [user])

    const getLocationData = () => {
        var body = {
            countryCode: cardFormik.values.card_address_country,
        }
        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [cardFormik.values.card_address_country])

    return (
        <>
            {/* {disabled ? <CustomLoader /> : ''} */}
            {props.loader && isLoading ? (
                <div className="text-center loader-overlay">
                    <Loader />
                </div>
            ) : (
                ''
            )}
            <Popup
                open={props.data.popup && props.data.status === 'new'}
                size="md"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle={t('add_card_details')}
            >
                <div className="cardDetails">
                    <form onSubmit={cardFormik.handleSubmit} autoComplete="nofill">
                        <div className="row">{mapData(cardDetails)}</div>
                        <div className="row">
                            <div className="col-12">
                                <PrimaryButton
                                    disabled={disabled}
                                    btnSize="large"
                                    type="submit"
                                    label={t('save')}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Popup>

            <CustomDialog
                title={t('confirmation')}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>
                    {formikSelection.values.status === 'delete'
                        ? props.allCards.length == 1
                            ? 'Atleast One Card Needed'
                            : t('del_msg')
                        : formikSelection.values.status === 'default'
                        ? 'Are you sure you want to make this default'
                        : 'Are you sure you want to change the status'}
                </h5>
                <div className="actionWrapper">
                    {formikSelection.values.status === 'delete' && props.allCards.length == 1 ? (
                        <Button onClick={() => changeChangeStatus()}>{t('confirm')}</Button>
                    ) : (
                        <>
                            <Button onClick={() => changeChangeStatus()}>{t('cancel')}</Button>
                            <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                                <PrimaryButton
                                    type="submit"
                                    label={t('confirm')}
                                    disabled={disabled}
                                />
                            </form>
                        </>
                    )}
                </div>
            </CustomDialog>

            <Popup
                open={props.data.popup && props.data.status === 'update'}
                handleClose={() => props.function(false, 'update', null)}
                size="md"
                modaltitle={t('Update_Card')}
            >
                <form onSubmit={updateCardformik.handleSubmit} autoComplete="nofill">
                    <div className="row">
                        {mapData(cardUpdate)}
                        <div className="col-12">
                            <PrimaryButton
                                disabled={disabled}
                                btnSize="large"
                                type="submit"
                                label={t('save')}
                            />
                        </div>
                    </div>
                </form>
            </Popup>
        </>
    )
}

export default CardDetails
