import React from 'react'
import Layout from '../Layout'
import './Location.css'

const Profile = (props) => {
    return (
        <Layout props={props}>
            <div className="entry-content">
                <section className="section">
                    <div className="wrap center-xs">
                        <div className="banner">
                            <img
                                src="https://lotsofauctions.com/wp-content/uploads/2024/01/PICK-UP-SCHEDULER-_Launch.png"
                                alt="Pick-Up Scheduler"
                                className="responsive-banner"
                            />
                        </div>
                        <div className="columns-shortcode">
                            <div className="flex-entry map-container">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6674.814862040029!2d-97.194844!3d33.229628!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864dc92e275be14d%3A0x89143105ff777fde!2s4801%20W%20University%20Dr%2C%20Denton%2C%20TX%2076207!5e0!3m2!1sen!2sus!4v1736145861480!5m2!1sen!2sus"
                                    className="responsive-map"
                                    allowfullscreen=""
                                    loading="lazy"
                                ></iframe>
                            </div>
                            <div className="flex-entry location-details">
                                <h2>Denton, Texas</h2>
                                <p>
                                    4801 W University Dr Suite 104
                                    <br />
                                    Denton, TX 76207
                                </p>
                                <h3>Hours</h3>
                                <p>Monday – Saturday: 9:00 AM to 6:00 PM</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="wrap center-xs">
                        <div className="columns-shortcode">
                            <div className="flex-entry map-container">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6701.651612626888!2d-96.881423!3d32.876328!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e9d771af91b21%3A0x5b94221cbe0a981f!2s2828%20Anode%20Ln%2C%20Dallas%2C%20TX%2075220!5e0!3m2!1sen!2sus!4v1736145976488!5m2!1sen!2sus"
                                    className="responsive-map"
                                    allowfullscreen=""
                                    loading="lazy"
                                ></iframe>
                            </div>
                            <div className="flex-entry location-details">
                                <h2>Dallas, Texas</h2>
                                <p>
                                    2828 Anode Lane
                                    <br />
                                    Dallas, TX 75220
                                </p>
                                <h3>Hours</h3>
                                <p>Monday – Saturday: 9:00 AM to 6:00 PM</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default Profile
