import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { fromAddress, setKey } from 'react-geocode'

function GoogleMapFooter(props) {
    const [location, setLocation] = useState({ lat: 33.229259, lng: -97.1942498 })

    const style = {
        height: '350px',
        maxWidth: '500px',
        width: '100%',
        border: '10px solid #fff',
        margin: '10px 0 0 auto',
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyANJvSnxieaGRhq_Ndt1Fe4MKDkDYE-SOM',
    })

    setKey('AIzaSyANJvSnxieaGRhq_Ndt1Fe4MKDkDYE-SOM')

    const [map, setMap] = useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const google_lat_lng = global.storeConfigration?.footer_map?.value
            ? global.storeConfigration.footer_map.value.split(',')
            : [33.229259, -97.1942498]

        const lat = parseFloat(google_lat_lng[0])
        const lng = parseFloat(google_lat_lng[1])

        setLocation({ lat, lng }) // Set the location state

        // const bounds = new window.google.maps.LatLngBounds()
        // bounds.extend({ lat, lng }) // Extend bounds to include the marker's position
        // map.fitBounds(bounds)
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (
        <>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={style}
                    center={location}
                    zoom={19}
                    onUnmount={onUnmount}
                    onLoad={onLoad}
                >
                    <Marker position={location} />
                </GoogleMap>
            ) : null}
        </>
    )
}

export default GoogleMapFooter
