import React, { useEffect, useState, useContext } from 'react'
import LotProduct from './lotProduct'
import _ from 'lodash'
import SlidePanel from '../LotsView'
import { useHistory, useLocation } from 'react-router'
import LotsListCard from './lotsListCard'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { handleRedirectInternal } from '../../Product/common/components'

// let gridItems = [];

const LotListProduct = (props) => {
    const history = useHistory()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const handleClose = () => {
        params.delete('product')
        history.replace({
            pathname: location.pathname,
            search: params.toString(),
        })
        setSelectedProduct(null)
    }
    const [catg, setCatg] = useState(
        new URLSearchParams(window.location.search.substring(1)).get('cat'),
    )
    const handleClick = async (id) => {
        // setSelectedProduct(id)

        // if (props.fromComponent === 'home') {
        //     setSelectedProduct(id)
        // } else {
        //     params.set('product', id)

        // history.replace({
        //     pathname: `/product/${item.id}`,
        //     search: params.toString(),
        // })
        handleRedirectInternal(
            history,
            global.storeDetails?.theme !== 19 ? `product/${id}` : `product/${id}?cat=${catg}`,
        )
    }
    useEffect(() => {
        // console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++')
        var product_id = params.get('product')
        if (product_id && !selectedProduct) {
            setSelectedProduct(product_id)
        }
    }, [window.location.search])
    const categoryTypes = {}
    global.allCategories.map((catgeory) => (categoryTypes[catgeory.id] = catgeory))
    return (
        <div className="search-wrapper">
            {props.cardType == 'list' ? (
                <>
                    {props.searchResult.map((item, index) => {
                        return (
                            <LazyLoadComponent key={index}>
                                <div className={props.className} key={`gl-${index}`}>
                                    <LotsListCard
                                        lotData={item}
                                        lotImages={item.lotImages}
                                        setViewProduct={props.setViewProduct}
                                        viewProduct={props.searchResult}
                                        key={index}
                                        selectedProduct={handleClick}
                                        type="lot"
                                        handleClick={handleClick}
                                        details={`product/${item.id}`}
                                        sliderTheme="multiseller"
                                        auction_city={props?.auction_city}
                                        bidBtnLabel={
                                            <>
                                                <span className="material-icons-outlined">
                                                    gavel
                                                </span>
                                                Bid
                                            </>
                                        }
                                        bidStatus={
                                            global.storeDetails.theme == 19 ? true : undefined
                                        }
                                    />
                                </div>
                            </LazyLoadComponent>
                        )
                    })}
                </>
            ) : (
                <div className="gridContainer">
                    {props.searchResult?.map((item, index) => {
                        return (
                            <LazyLoadComponent key={index}>
                                <div className={props.className} key={`gl-${index}`}>
                                    <>
                                        <LotProduct
                                            data={item}
                                            lotImages={item.lotImages}
                                            link={`/gallery/product-view/${item.id}`}
                                            handleClick={handleClick}
                                            timerTheme={props.timerTheme}
                                            type={props.type ? props.type : ''}
                                            cardTheme={props.cardTheme}
                                            noTimer={props.noTimer}
                                            getAllProducts={props.getAllProducts}
                                            setViewProduct={props.setViewProduct}
                                            viewProduct={props.searchResult}
                                            audio={props.audio}
                                            auction_city={props.auction_city}
                                            fromComponent={props.fromComponent}
                                            productViewLink={
                                                props.cardTheme === 'auctioneer'
                                                    ? `productview/${item.id}/${
                                                          item.auction ? 'auction' : 'buynow'
                                                      }/${
                                                          (item?.custom_field_9?.includes('}')
                                                              ? JSON.parse(item.custom_field_9)
                                                              : {}
                                                          )?.game_theme
                                                              ?.toLowerCase()
                                                              ?.split(' ')
                                                              ?.join('-') +
                                                          '-' +
                                                          item?.subcategory
                                                              ?.split(',')
                                                              ?.map(
                                                                  (val) =>
                                                                      categoryTypes[
                                                                          val
                                                                      ]?.description?.toLowerCase() ??
                                                                      '',
                                                              )
                                                              ?.join('-')
                                                              .split(' ')
                                                              .join('-')
                                                      }`
                                                    : global.storeDetails?.theme === 19
                                                    ? `/product/${item.id}?cat=${catg}`
                                                    : global.storeDetails?.theme === 18 || 2
                                                    ? `/product/${item.id}`
                                                    : props.productViewLink
                                            }
                                            bidStatus="true"
                                            timerIcon={true}
                                            hideQuickBid={
                                                props.hideQuickBid ? props.hideQuickBid : false
                                            }
                                        />
                                    </>
                                </div>
                            </LazyLoadComponent>
                        )
                    })}
                </div>
            )}

            {/* <SlidePanel
                selectedLot={selectedProduct}
                handleClose={handleClose}
                type={props.type ? props.type : ''}
                getAllProducts={props.getAllProducts}
                enableZoomOnHover={props.enableZoomOnHover}
            /> */}
        </div>
    )
}

export default LotListProduct
