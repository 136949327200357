import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Popup from '../../../Product/components/organisms/Popup'
import { Copyrightinfo, FooterLogo } from '../../../utils'
import MetaTag from '../../../utils/CommonFunctionality/SEO/MetaTag'
import GoogleMap from '../../../utils/CommonFunctionality/GoogleMap'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import './Footer.css'
import { use } from 'i18next'

function Footer() {
    const [openModal, setModal] = useState(false)
    const [anchormenu, setanchormenu] = useState(null)
    const isMobile = useMediaQuery('(max-width: 768px)')

    const changeDialogStatus = () => {
        setModal(!openModal)
    }
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagram = global?.storeDetails?.instagram
    const youtube = global?.storeDetails?.youtube
    const tiktok = global?.storeDetails?.tiktok

    return (
        <footer className="footer-main-standard noPrint">
            <div className="container">
                <div className="ftrWrprVw d-flex align-items-start justify-content-between">
                    <div className="lgAdCntnr">
                        <div className="ftrLgCntnr">
                            <FooterLogo className="footerLogo" />
                        </div>
                        {!isMobile && (
                            <div className="adrsFlds d-md-block">
                                <Copyrightinfo />
                                <ul className="footerLinks social-links">
                                    {facebookLink && (
                                        <li className="facebook">
                                            <a
                                                href={facebookLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Facebook"
                                            >
                                                <img src="/lots-images/facebook.png" alt="" />
                                            </a>
                                        </li>
                                    )}
                                    {tiktok && (
                                        <li className="pinterest">
                                            <a
                                                href=""
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="tiktok"
                                            >
                                                <img src="/lots-images/tiktok.png" alt="" />
                                            </a>
                                        </li>
                                    )}
                                    {youtube && (
                                        <li className="pinterest">
                                            <a
                                                href={youtube}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Youtube"
                                            >
                                                <img src="/lots-images/youtube.png" alt="" />
                                            </a>
                                        </li>
                                    )}

                                    {instagram && (
                                        <li className="instagram">
                                            <a
                                                href={instagram}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Instagram"
                                            >
                                                <img src="/lots-images/instagram.png" alt="" />
                                            </a>
                                        </li>
                                    )}
                                    {googleLink && (
                                        <li className="instagram">
                                            <a
                                                href={googleLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Linkedin"
                                            >
                                                <img src="/lots-images/linkedin.png" alt="" />
                                            </a>
                                        </li>
                                    )}
                                    {twitterLink && (
                                        <li className="pinterest">
                                            <a
                                                href={twitterLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="Twitter"
                                            >
                                                <img src="/lots-images/x.png" alt="" />
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="twoDivCls">
                        <div className="ftrLnkWrpr d-flex align-items-start">
                            <div className="lnkGrdTb">
                                <h4>Buy</h4>
                                <ul className="footerLinks">
                                    <li className="footHiw">
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            How it works
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/location_hours">
                                            Location & Hours
                                        </NavLink>
                                    </li>
                                    {global.storeDetails?.shipping_policy ? (
                                        <li>
                                            <NavLink activeClassName="active" to="/shipping_policy">
                                                Shipping Policy
                                            </NavLink>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    <li>
                                        <NavLink activeClassName="active" to="/faq">
                                            FAQs
                                        </NavLink>
                                    </li>
                                    <li>
                                        <a href="https://lotsofauctions.com/category/blog/">Blog</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="lnkGrdTb">
                                <h4>About</h4>
                                <ul className="footerLinks">
                                    <li>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            Our Story
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            User Agreement
                                        </NavLink>
                                    </li>
                                    {global.storeDetails?.privacy_policy ? (
                                        <li>
                                            <NavLink activeClassName="active" to="/privacy_policy">
                                                Privacy Policy
                                            </NavLink>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {global.storeDetails?.jobs ? (
                                        <li className="footHiw">
                                            <NavLink activeClassName="active" to="/jobs">
                                                Job Opportunities
                                            </NavLink>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                </ul>
                            </div>

                            <div className="lnkGrdTb">
                                <h4>Help & Contact</h4>
                                <ul className="footerLinks">
                                    <li className="footHiw">
                                        <NavLink activeClassName="active" to="/contact_us">
                                            Contact Us
                                        </NavLink>
                                    </li>
                                    {contactEmail && (
                                        <li>
                                            <a href={`mailto:${contactEmail}`} className="cvc-link">
                                                {contactEmail}
                                            </a>
                                        </li>
                                    )}
                                    {global.storeConfigration?.hide_phone?.value != 1 &&
                                        contactPhone && (
                                            <li>
                                                <a
                                                    href={`tel:${contactPhone}`}
                                                    className="cvc-link"
                                                >
                                                    {contactPhone}
                                                </a>
                                            </li>
                                        )}
                                </ul>
                            </div>

                            {/* <div className="lnkGrdTb d-md-none">
                                <h4>ADDRESS</h4>
                                <div className="adrsFlds">
                                    <p>
                                        {global?.storeSeller?.address1}{' '}
                                        {global?.storeSeller?.address2 != '' &&
                                            global?.storeSeller?.address2 != null && (
                                                <>
                                                    <br />
                                                    {global?.storeSeller?.address2}
                                                </>
                                            )}
                                        <br />
                                        {global?.storeSeller?.city}, {global?.storeSeller?.state}
                                        <br />
                                        {global?.storeSeller?.country}, {global?.storeSeller?.zip}
                                    </p>
                                </div>
                            </div>

                            <ul className="footer-main-standard-social-list">
                            {facebookLink && (
                                <li className="facebook">
                                    <a
                                        href={facebookLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Facebook"
                                    >
                                        <i className="fab fa-facebook"></i>
                                    </a>
                                </li>
                            )}
                            {twitterLink && (
                                <li className="pinterest">
                                    <a
                                        href={twitterLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Twitter"
                                    >
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                            )}
                            {googleLink && (
                                <li className="instagram">
                                    <a
                                        href={googleLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Linkedin"
                                    >
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                </li>
                            )}
                            {instagram && (
                                <li className="instagram">
                                    <a
                                        href={instagram}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Linkedin"
                                    >
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                            )}
                        </ul> */}

                            {/* <div className="d-flex align-items-center justify-content-center flex-wrap">
                            {contactEmail && (
                                <>
                                    <a href={`mailto:${contactEmail}`} className="cvc-link mr-2">
                                        <span className="material-icons">drafts</span>
                                        {contactEmail}
                                    </a>
                                    <span>|</span>
                                </>
                            )}
                            {contactPhone && (
                                <a href={`tel:${contactPhone}`} className="cvc-link ml-2">
                                    <span className="material-icons">call</span>
                                    {contactPhone}
                                </a>
                            )}
                        </div> */}
                        </div>
                        {global.storeConfigration?.footer_map?.value ? (
                            <div className="myMap">
                                <GoogleMap />
                            </div>
                        ) : null}
                    </div>
                </div>
                {isMobile && (
                    <div className="adrsFlds d-md-block">
                        <Copyrightinfo />
                        <ul className="footerLinks social-links">
                            {facebookLink && (
                                <li className="facebook">
                                    <a
                                        href={facebookLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Facebook"
                                    >
                                        <img src="/lots-images/facebook.png" alt="" />
                                    </a>
                                </li>
                            )}
                            {tiktok && (
                                <li className="pinterest">
                                    <a
                                        href={tiktok}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="tiktok"
                                    >
                                        <img src="/lots-images/tiktok.png" alt="" />
                                    </a>
                                </li>
                            )}
                            {youtube && (
                                <li className="pinterest">
                                    <a
                                        href={youtube}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Youtube"
                                    >
                                        <img src="/lots-images/youtube.png" alt="" />
                                    </a>
                                </li>
                            )}

                            {instagram && (
                                <li className="instagram">
                                    <a
                                        href={instagram}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Instagram"
                                    >
                                        <img src="/lots-images/instagram.png" alt="" />
                                    </a>
                                </li>
                            )}
                            {googleLink && (
                                <li className="instagram">
                                    <a
                                        href={googleLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Linkedin"
                                    >
                                        <img src="/lots-images/linkedin.png" alt="" />
                                    </a>
                                </li>
                            )}
                            {twitterLink && (
                                <li className="pinterest">
                                    <a
                                        href={twitterLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Twitter"
                                    >
                                        <img src="/lots-images/x.png" alt="" />
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
            {global.storeConfigration?.meta_tag_pixel_pinacle?.value ? <MetaTag /> : ''}

            <Popup
                open={openModal}
                size="md"
                handleClose={changeDialogStatus}
                modaltitle="Schedule Pickup"
            >
                <div className="schedulePickup">
                    <iframe
                        height={600}
                        src={global.storeConfigration?.external_schedule_pickup_link?.value}
                    ></iframe>
                </div>
            </Popup>
        </footer>
    )
}

export default Footer
